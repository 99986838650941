import React, { useState } from "react"
import axios from "axios"
import { useQuery } from "react-query"
import { Label } from "reactstrap"

const CategoryFilter = ({ onCategorySelect }) => {
  const [categoryId, setCategoryId] = useState("")
  const {
    data: categoryData,
    isLoading,
    isError,
    error,
  } = useQuery("categories", async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/attributes/get-categories`,
      { withCredentials: true }
    )
    return response.data
  })

  const handleCategoryChange = e => {
    const selectedValue = e.target.value
    setCategoryId(selectedValue)
    onCategorySelect(selectedValue) 
  }

  const renderCategories = categories => {
    return categories.map(category => (
      <optgroup key={category.category_id} label={category.category_name}>
        {(!category.children_categories ||
          category.children_categories.length === 0) && (
          <option value={category.category_id}>{category.category_name}</option>
        )}
        {category.children_categories &&
          category.children_categories.length > 0 &&
          category.children_categories.map(subCategory => (
            <React.Fragment key={subCategory.category_id}>
              <option
                value={subCategory.category_id}
                style={{
                  fontWeight:
                    subCategory.children_categories &&
                    subCategory.children_categories.length > 0
                      ? "800"
                      : "normal",
                  color:
                    subCategory.children_categories &&
                    subCategory.children_categories.length > 0
                      ? "#0b0b0b"
                      : "black",
                }}
                disabled={
                  subCategory.children_categories &&
                  subCategory.children_categories.length > 0
                }
              >
                {subCategory.category_name}
              </option>
              {subCategory.children_categories &&
                subCategory.children_categories.length > 0 &&
                subCategory.children_categories.map(childSubCategory => (
                  <option
                    key={childSubCategory.category_id}
                    value={childSubCategory.category_id}
                    style={{
                      fontWeight:
                        childSubCategory.children_categories &&
                        childSubCategory.children_categories.length > 0
                          ? "bold"
                          : "normal",
                    }}
                    disabled={
                      childSubCategory.children_categories &&
                      childSubCategory.children_categories.length > 0
                    }
                  >
                    &nbsp; &nbsp; &nbsp; {childSubCategory.category_name}
                    {childSubCategory.children_categories &&
                      childSubCategory.children_categories.length > 0 && (
                        <span className="icon-down" aria-hidden="true">
                          ↓
                        </span>
                      )}
                  </option>
                ))}
            </React.Fragment>
          ))}
      </optgroup>
    ))
  }

  if (isLoading) {
    return <span>Yükleniyor...</span>
  }

  if (isError) {
    console.error(error)
    return <span>Kategorileri yüklerken bir sorun oluştu.</span>
  }

  const textFieldStyle = {
    backgroundColor: "white",
    color: "black",
    appearance: "auto",
  }

  return (
    <div className="category-filter">
      <select
        value={categoryId || ""}
        onChange={handleCategoryChange}
        className="form-control select2"
        style={textFieldStyle}
      >
        <option value="">Kategoriler</option>
        {renderCategories(categoryData)} 
      </select>
    </div>
  )
}

export default CategoryFilter

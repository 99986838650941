// VariationItem.jsx

import React from "react";
import { Col, Row } from "reactstrap";
import stockImage from "../../../../../../assets/images/logo/logo-light.svg"

const VariationItem = ({ variation, handleSizeClick, clickedSizes }) => {
    const imageStyle = { width: "6rem", height: "6rem", border: "1px solid #E4E4E4", borderRadius: "8px", objectFit: "cover" };

    return (
        <Row className="py-2 d-flex align-items-center">
            <Col xs="6" sm="4" xl="2" className="d-flex flex-column align-items-center">
                {variation?.variation_media ? (
                    <img src={variation?.variation_media[0]} style={imageStyle} />
                ) : (
                    <img src={stockImage} style={imageStyle} />
                )}
            </Col>
            <Col xs="6" sm="8" xl="10">
                <Row>
                    {variation.quantities_for_sizes ? (
                        Object.entries(variation.quantities_for_sizes).map(([size, quantity], index) => {
                            const uniqueIdentifier = `${size}-${variation.variation_id}`;
                            const isClickable = quantity > 0; // Check if quantity is greater than 0
                            const isClicked = clickedSizes[uniqueIdentifier];

                            return (
                                <Col key={index} xs="6" sm="4" md="3" lg="2" className="p-0">
                                    <div
                                        className={`d-flex flex-column p-1 ${!isClickable ? "disabled" : ""}`}
                                        style={{
                                            border: isClicked ? "1px solid #556EE6" : "1px solid #EFF2F7",
                                            backgroundColor: isClicked ? "#DFE5FF" : quantity === 0 && "#FFF",
                                            cursor: isClickable ? "pointer" : "not-allowed",
                                        }}
                                        onClick={() => {
                                            if (isClickable) {
                                                handleSizeClick(variation, uniqueIdentifier, variation.color);
                                            }
                                        }}
                                    >
                                        <span style={{ fontWeight: "600", color: isClicked ? "#556EE6" : quantity === 0 && "" }}>
                                            {size.toLocaleUpperCase()}
                                        </span>
                                        <span style={{ fontWeight: "600", color: isClicked && "#FF0A0A" }}>
                                            {isClickable ? (isClicked ? 0 : quantity) : 0}
                                        </span>
                                    </div>
                                </Col>
                            );
                        })
                    ) : null}
                </Row>
            </Col>
        </Row>
    );
};

export default VariationItem;

import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import { TiDelete } from "react-icons/ti"
import Form from "react-bootstrap/Form"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import cloneDeep from "lodash/cloneDeep"
import Tag from "./Tag"

import { useQuery, useMutation } from "@tanstack/react-query"
import axios from "axios"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ColorHex from "./colorHex"
import UploadMediaModal from "./UploadMediaModal"
import { Spin, Modal, Select, Switch } from "antd"
import { EditOutlined } from '@ant-design/icons';

const EcommerceAddProduct = () => {
  const history = useNavigate()

  document.title = "Ürün Ekle | Butiko"

  const [hashtagInput, setHashtagInput] = useState("")
  const [hashtags, setHashtags] = useState([])
  const [colorInput, setColorInput] = useState("")
  const [colors, setColors] = useState([])
  const [sizeInput, setSizeInput] = useState("")
  const [sizes, setSizes] = useState([])
  const [tableData, setTableData] = useState([])
  const [categoryId, setCategoryId] = useState("")
  const [productName, setProductName] = useState("")
  const [productFeatures, setProductFeatures] = useState("")
  const [productDesc, setProductDesc] = useState("")
  const [producManufacturerBrand, setProducManufacturerBrand] = useState("")
  const [productBrand, setProductBrand] = useState("")
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [selectedValues, setSelectedValues] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [newDesignerName, setNewDesignerName] = useState("")
  const [selectedDesignerId, setSelectedDesignerId] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [switchActive, setSwitchActive] = useState(false)
  const [errors, setErrors] = useState([])
  const [showEditButton, setShowEditButton] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [part1Errors, setPart1Errors] = useState({
    productName: "",
    productDesc: "",
    categoryId: "",
    productBrand: "",
    size: "",
    color: "",
    productFeatures: "",
    manufacturerBrand: "",
    sizeEnter: "",
    colorEnter: "",
  })
  const [rows, setRows] = useState([])

  const [products, setProducts] = useState([
    //   {
    //   product_id: 0,
    //   color: {},
    //   sizes: [
    //     {
    //       size: "",
    //       barcode: "",
    //       tax_rat: "0",
    //       price_in_butiko: 0,
    //       price_out_butiko: 0,
    //       quantity: 0
    //     }
    //   ],
    //   photo: [
    //   ]
    // }
  ])

  const postNewProductPart1 = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/add-new-product`
    let isValid = true

    if (productDesc.trim() === "") {
      isValid = false
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        productDesc: "Ürün Açıklaması Boş Olamaz",
      }))
    } else {
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        productDesc: "",
      }))
    }

    if (categoryId === "") {
      isValid = false
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        categoryId: "Kategori Boş Olamaz",
      }))
    } else {
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        categoryId: "",
      }))
    }

    if (sizes.length === 0 && sizeInput) {
      isValid = false
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        sizeEnter: "Lütfen Yazdıktan Sonra ENTER'a Basın",
      }))
      console.log(part1Errors)
    } else {
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        sizeEnter: "",
      }))
    }
    if (sizes.length === 0 && !sizeInput) {
      isValid = false
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        size: "Lütfen Yazdıktan Sonra ENTER'a Basın",
      }))
    } else {
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        size: "",
      }))
    }
    if (sizes.length > 0) {
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        size: "",
        sizeEnter: "",
      }))
    }
    if (colors.length === 0 && colorInput) {
      isValid = false
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        colorEnter: "Lütfen Yazdıktan Sonra ENTER'a Basın",
      }))
    } else {
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        color: "",
      }))
    }
    if (colors.length === 0 && !colorInput) {
      isValid = false
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        color: "Lütfen Yazdıktan Sonra ENTER'a Basın",
      }))
    } else {
      setPart1Errors(prevErrors => ({
        ...prevErrors,
        color: "",
      }))
    }
    const attributeIdsArray = Object.values(selectedValues)
    const finalData = {
      product_name: productName,
      features: productFeatures,
      hashtags: hashtags,
      product_description: productDesc,
      category_id: parseInt(categoryId),
      manufacturer_brand: producManufacturerBrand,
      product_brand: productBrand,
      attribute_value_ids: attributeIdsArray,
      switch_value: switchActive ? 1 : 0,
      designer_id: selectedDesignerId || null,
    }
    if (isValid) {
      return axios.post(finalUrl, finalData, { withCredentials: true })
    } else {
      // throw new Error('Value must be a non-negative number');
    }
  }

  const postNewProductPart2 = product => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/add-new-variation-sizes`
    const deepCopy = cloneDeep(product)
    delete deepCopy.photo
    // console.log(deepCopy);
    return axios.post(finalUrl, deepCopy, { withCredentials: true })
  }
  const mutatePart2 = useMutation({
    mutationFn: postNewProductPart2,
    onSuccess: responseData => {
      // console.log(responseData.data.variation_id);
    },
    onError: error => {
      console.log(error)
      console.log("dsfsdfdsfdsfsdfsdfsd")
      if (
        error.response.data.detail[0].msg === "unathorized action" ||
        error.response.data.detail[0].msg === "invalid jwt"
      ) {
        history("/login")
      } else if (error.response.data.detail[0].msg === "Field required") {
        toast.error("urun bilgisi bus", { toastId: "emptyProductDetail" })
      } else if (
        error.response.data.detail[0].msg ===
        "Input should be '0', '1', '10' or '20'"
      ) {
        toast.info("Lütfen bir KDV değer seçiniz", { toastId: "noKDV" })
      } else if (
        error.response.data.detail[0].msg ==
        "Value error, price_in_butiko should be smaller than price_outside_butiko"
      ) {
        toast.info("Fiyat butikoda, Fiyat butiko dishi'dan küçük olmalıdır", {
          toastId: "lowPrice",
        })
      } else if (
        error.response.data.detail[0].msg ==
        "Input should be greater than or equal to 0"
      ) {
        toast.error("Giriş 0'dan büyük veya 0'a eşit olmalıdır", {
          toastId: "notGreaterZero",
        })
      } else if (
        error.response.data.detail[0].msg.includes("color duplication")
      ) {
        toast.error("Her renkten bir tane olabilir.", {
          toastId: "color-duplication",
        })
      } else {
        toast.error("Bilinmeyen Bir Hata Oluştu", {
          toastId: "mutatePart2Error",
        })
      }
    },
  })
  const [product_id, setProduct_id] = useState(0)
  const mutatePart1 = useMutation({
    mutationFn: postNewProductPart1,
    onSuccess: responseData => {
      setProduct_id(responseData.data.product_id)
      // console.log(responseData.data.product_id);
      products.map(product => {
        product.product_id = responseData.data.product_id
      })
      // console.log(products);
      const mutationPromises = products.map(product => {
        // Returning the promise from mutateAsync() call
        return mutatePart2.mutateAsync(product).then(res => {
          product.variation_id = res.data.variation_id
          return res
        })
      })
      let var_ids = []
      // console.log(mutationPromises);
      Promise.all(mutationPromises)
        .then(results => {
          // console.log('All mutations completed successfully:', results);
          results.forEach(result => var_ids.push(result.data.variation_id))
          // console.log(var_ids);
          setModalOpen(true)
          // setProducts([])
          // setProductBrand("")
          // setProducManufacturerBrand('')
          // setProductDesc('')
          // setProductFeatures('')
          // setProductName("")
          // setCategoryId("")
          // setSizes([])
          // setColors([])
          // setHashtags([])
          // toastr.success("İşlem Tamamlandı", "")
        })
        .catch(error => {
          // At least one mutation failed
          console.error("One or more mutations failed:", error)
          // toastr.error("Bir veya kac renk de Hata Oluştu", "")
        })
    },
    onError: error => {
      // console.log(error);
      if (
        error.response.data.detail[0].msg === "unathorized action" ||
        error.response.data.detail[0].msg === "invalid jwt"
      ) {
        history("/login")
      }
      toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "mutatePart1Error" })
    },
  })

  useEffect(() => {
    const initialTableData = []
    colors.forEach(color => {
      sizes.forEach(size => {
        initialTableData.push({ color, size })
      })
    })
    setTableData(initialTableData)
  }, [colors, sizes])

  const textFieldStyle = {
    backgroundColor: "#F3F3F9",
    color: "black",
    appearance: "auto",
  }

  const initialCheckedRows = []
  for (let i = 0; i < colors.length * sizes.length; i++) {
    initialCheckedRows.push(false)
  }

  const handleInputChange = (index, sizeIndex, e) => {
    if (
      e?.target?.type === "file" &&
      e?.target?.files[0] && // Check if a file is selected
      ((e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/jpg" &&
        e.target.files[0].type !== "image/png") ||
        e.target.files[0].size < 500)
    ) {
      toast.info(
        "Format jpg veya png olmalı ve boyutu 1kb'den büyük olmalıdır",
        { toastId: "formatError" }
      )
      // console.log("oisdhfoiudsh");
    } else {
    }
    if (
      e?.target?.type === "file" &&
      e.target.files[0] !== undefined &&
      e.target.files[0].size > 500 &&
      (e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg")
    ) {
      // console.log(e.target.files[0].type);
      const newInputValues = [...products]
      newInputValues[index].photo.push(e.target.files[0])
      setProducts(newInputValues)
      // console.log(products);
    } else if (e.files) {
      // console.log(e.files[0]);
      const newInputValues = [...products]
      Array.from(e.files).forEach(file => {
        if (file.size > 520) {
          newInputValues[index].photo.push(file)
        } else {
          toast.info("Dosyalar 5 kb'den büyük olmalıdır", {
            toastId: "sizeError",
          })
        }
      })

      setProducts(newInputValues)
      // console.log(products);
    } else {
      // Handling regular input
      // console.log(sizeIndex);

      const key = e.target.name
      let value
      if (
        key === "price_in_butiko" ||
        key === "price_outside_butiko" ||
        key === "quantity"
      ) {
        value = parseFloat(e.target.value)
      } else if (key === "tax_rate") {
        value = e.target.value
      } else {
        value = e.target.value
      }

      // Your validation logic here
      let isValid2 = true
      let errorMessage = ""

      // Example validation: Check if the value is empty
      if (value === "") {
        isValid2 = false
        errorMessage = "Bu alan zorunlu."
      } else if (
        key === "tax_rate" &&
        ![0, 1, 10, 20].includes(Number(value))
      ) {
        isValid2 = false
        errorMessage = "Vergi oranı 0, 1, 10 veya 20 olmalıdır."
      } else if (
        key === "price_outside_butiko" &&
        parseFloat(value) <=
          parseFloat(products[index]["sizes"][sizeIndex].price_in_butiko)
      ) {
        // Additional validation: Check if 'price_in_butiko' is lower than 'price_outside_butiko'
        isValid2 = false
        errorMessage =
          "Butiko dışındaki fiyat, butiko'daki fiyattan daha yüksek olmalıdır."
      } else if (
        key === "price_in_butiko" &&
        parseFloat(value) >=
          parseFloat(products[index]["sizes"][sizeIndex].price_outside_butiko)
      ) {
        // Additional validation: Check if 'price_in_butiko' is lower than 'price_outside_butiko'
        isValid2 = false
        errorMessage =
          "Butiko'daki fiyat, butiko dışındaki fiyattan daha düşük olmalıdır."
      }

      // Update the state to store validation errors
      setErrors(prevErrors => {
        const newErrors = [...prevErrors]
        newErrors[index] = newErrors[index] || []
        newErrors[index][sizeIndex] = { [key]: errorMessage }
        return newErrors
      })

      if (rows.some(([num1, num2]) => num1 === index && num2 === sizeIndex)) {
        rows.forEach(([num1, num2]) =>
          setProducts(prevProducts =>
            prevProducts.map((product, currentIndex) =>
              currentIndex === num1
                ? {
                    ...product,
                    sizes: product.sizes.map((item, indexx) =>
                      indexx === num2 ? { ...item, [key]: value } : item
                    ),
                  }
                : product
            )
          )
        )
      } else {
        setProducts(prevProducts =>
          prevProducts.map((product, currentIndex) =>
            currentIndex === index
              ? {
                  ...product,
                  sizes: product.sizes.map((item, indexx) =>
                    indexx === sizeIndex ? { ...item, [key]: value } : item
                  ),
                }
              : product
          )
        )
      }
    }
  }
  const handleHashtagInputChange = event => {
    setHashtagInput(event.target.value)
  }

  const handleHashtagInputKeyDown = event => {
    if (event.key === "Enter" && hashtagInput.trim() !== "") {
      event.preventDefault()
      setHashtags([...hashtags, hashtagInput.trim()])
      setHashtagInput("")
    }
  }

  const handleHashtagRemove = hashtagToRemove => {
    setHashtags(hashtags.filter(hashtag => hashtag !== hashtagToRemove))
  }

  const handleColorInputChange = event => {
    setColorInput(event.target.value)
  }

  const handleColorInputKeyDown = event => {
    if (
      (event.key === "Enter" || event.keyCode === 13) &&
      colorInput.trim() !== ""
    ) {
      //e.keyCode===13
      event.preventDefault()
      let colorHexx = ColorHex(colorInput.toLowerCase())
      colors.forEach(color => {
        if (
          color.color_hex == colorHexx ||
          color.color_name == colorInput.trim()
        ) {
          toast.info(
            "Aynı renkten birden çok olamaz, lütfen başka bir renk seçin ya da rengin üzerine tıklayıp rengi değiştirin.",
            { toastId: "color-duplication" }
          )
        }
      })
      if (sizes.length > 0) {
        //this is for when user enters a color after entering some sizes
        const newColor = { color_name: colorInput.trim(), color_hex: colorHexx }
        const curentSizes = sizes.map(size => {
          return {
            size: size.size,
            barcode: "",
            tax_rate: "",
            price_in_butiko: "",
            price_outside_butiko: "",
            quantity: "",
          }
        })
        // console.log(curentSizes);
        setProducts([
          ...products,
          {
            product_id: null,
            color: newColor,
            sizes: curentSizes,
            photo: [],
          },
        ])
        setColors([...colors, newColor])
        setColorInput("")
        // console.log(products);
      } else {
        let colorHexx = ColorHex(colorInput.toLowerCase())
        const newColor = { color_name: colorInput.trim(), color_hex: colorHexx }
        setProducts([
          ...products,
          {
            product_id: null,
            color: newColor,
            sizes: [],
            photo: [],
          },
        ])
        setColors([...colors, newColor])
        setColorInput("")
        // console.log(products);
      }
    }
  }

  const handleColorRemove = colorToRemove => {
    setColors(colors.filter(color => color.color_name !== colorToRemove))
    // console.log(colors);
    setProducts(
      products.filter(product => product.color.color_name !== colorToRemove)
    )
  }
  const handleColorChange = (e, colorName) => {
    const deepCopy = cloneDeep(products)
    const colorToChange = deepCopy.map(colors =>
      colors.color.color_name === colorName
        ? { ...colors, color: { ...colors.color, color_hex: e.target.value } }
        : colors
    )
    // console.log(colorToChange);
    // console.log(e.target.value);
    // console.log(colorName);
    setColors(
      colors.map(color =>
        color.color_name === colorName
          ? { ...color, color_hex: e.target.value }
          : color
      )
    )

    setProducts(colorToChange)
  }
  const handleDeleteSize = (index, sizeIndex) => {
    const deepCopy = cloneDeep(products)
    deepCopy[index].sizes.splice(sizeIndex, 1)
    setProducts(deepCopy)
    console.log(products)
  }
  const handleSizeInputChange = event => {
    setSizeInput(event.target.value)
  }

  const handleSizeInputKeyDown = event => {
    if (event.key === "Enter" && sizeInput.trim() !== "") {
      event.preventDefault()
      let newProducts = [...products]
      const newSize = {
        size: sizeInput.trim(),
        barcode: "",
        tax_rate: "",
        price_in_butiko: "",
        price_outside_butiko: "",
        quantity: "",
      }
      const updatedProducts = newProducts.map(product => {
        let updatedSizes = [...product.sizes, newSize]
        product.sizes = updatedSizes
        return product
      })
      setProducts(updatedProducts)
      const newSizee = { size: sizeInput.trim(), color: "test" }
      setSizes([...sizes, newSizee])
      setSizeInput("")
      // console.log(products);
      const newwSize = { size: sizeInput.trim() }
      setSizes([...sizes, newwSize])
      setSizeInput("")
    }
  }

  const handleSizeRemove = sizeToRemove => {
    setSizes(sizes.filter(size => size.size !== sizeToRemove))
    const newData = [...products]
    const newwData = newData.map(product =>
      product.sizes.filter(size => size.size !== sizeToRemove)
    )
    const final = newData.map(product => (product.sizes = newwData[0]))
    setProducts(newData)
  }

  const handleSelectAllSizes = () => {
    let allRows = []
    products.forEach((product, productIndex) => {
      product.sizes.forEach((size, sizeIndex) => {
        allRows.push([productIndex, sizeIndex])
      })
    })
    if (rows.length !== allRows.length) {
      // console.log("all selected");
      setRows(allRows)
      setSelectAllChecked(true)
    } else {
      // console.log("not all");
      setRows([])
      setSelectAllChecked(false)
    }
  }

  const handleCheckboxChange = (index, sizeIndex, e) => {
    let rowsToUpdate = [...rows]
    if (
      rowsToUpdate.some(([num1, num2]) => num1 === index && num2 === sizeIndex)
    ) {
      rowsToUpdate = rowsToUpdate.filter(
        ([num1, num2]) => !(num1 === index && num2 === sizeIndex)
      )
      setRows(rowsToUpdate)
    } else {
      rowsToUpdate.push([index, sizeIndex])
      setRows(rowsToUpdate)
    }
  }

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  }

  const formats = [
    "header",
    "font",
    "list",
    "bold",
    "italic",
    "underline",
    "align",
  ]
  //!  KATEGORİ APİ
  const getCategories = () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/attributes/get-categories`,
      {
        withCredentials: true,
      }
    )
  }
  //!  Attributes APİ
  const getAttributes = categoryId => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/attributes/get-attributes?category_id=${categoryId}`,
      { withCredentials: true }
    )
  }
  const {
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
    data: categoryData,
    error: categoriesError,
  } = useQuery({
    queryKey: ["get-categories"],
    queryFn: getCategories,
  })
  const {
    isLoading: isAttributesLoading,
    isError: isAttributesError,
    data: attributeData,
    error: attributesError,
  } = useQuery({
    queryKey: ["get-attributes", categoryId],
    queryFn: () => getAttributes(categoryId),
    enabled: !!categoryId,
  })
  //!  Designers APİ
  const getDesigners = () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/get-designers`,
      { withCredentials: true }
    )
  }
  const {
    isLoading: isDesignersLoading,
    isError: isDesignersError,
    data: designersData,
    error: designersError,
  } = useQuery({
    queryKey: ["get-designers"],
    queryFn: getDesigners,
  })
  if (isCategoriesLoading || isAttributesLoading || isDesignersLoading) {
    return <span>loading...</span>
  }

  if (isCategoriesError) {
    if (
      categoriesError?.response?.data?.detail[0]?.msg ===
        "unauthorized action" ||
      categoriesError?.response?.data?.detail[0]?.msg === "invalid jwt"
    ) {
      history("/login")
    }
    toast.error("Kategori getirilirken bir hata oluştu.", {
      toastId: "categoryError",
    })
  }
  if (isDesignersError) {
    if (
      isDesignersError?.response?.data?.detail[0]?.msg ===
        "unauthorized action" ||
      isDesignersError?.response?.data?.detail[0]?.msg === "invalid jwt"
    ) {
      history("/login")
    }
    toast.error("Designerlar getirilirken bir hata oluştu.", {
      toastId: "designersError",
    })
  }

  if (isAttributesError) {
    if (
      attributesError?.response?.data?.detail[0]?.msg ===
        "unauthorized action" ||
      attributesError?.response?.data?.detail[0]?.msg === "invalid jwt"
    ) {
      history("/login")
    }
    toast.error("Attribute getirilirken bir hata oluştu.", {
      toastId: "attributeError",
    })
  }

  //? YENİ EKLEDİĞİM KISIMLAR
  const handleCategoryChange = e => {
    const value = e.target.value
    if (value !== categoryId) {
      setIsModalVisible(false)
    }
    setCategoryId(value)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    const requiredAttributesSelected = attributeData?.data
      ?.filter(attribute => attribute.is_required === 1)
      .every(attribute => {
        const selectValue = selectedValues[attribute.attribute_id]
        return selectValue !== undefined && selectValue !== null
      })

    if (!requiredAttributesSelected) {
      toast.error("Zorunlu olan özellikleri seçmelisiniz.")
    } else {
      setShowEditButton(true) 
      setIsModalVisible(false)
    }
  }

  const renderCategories = categories => {
    return categories.map(category => (
      <optgroup key={category.category_id} label={category.category_name}>
        {(!category.children_categories ||
          category.children_categories.length === 0) && (
          <option value={category.category_id}>{category.category_name}</option>
        )}
        {category.children_categories &&
          category.children_categories.length > 0 &&
          category.children_categories.map(subCategory => (
            <>
              <option
                key={subCategory.category_id}
                value={subCategory.category_id}
                style={{
                  fontWeight:
                    subCategory.children_categories &&
                    subCategory.children_categories.length > 0
                      ? "800"
                      : "normal",
                  color:
                    subCategory.children_categories &&
                    subCategory.children_categories.length > 0
                      ? "#0b0b0b"
                      : "black",
                }}
                disabled={
                  subCategory.children_categories &&
                  subCategory.children_categories.length > 0
                }
              >
                {subCategory.category_name}{" "}
              </option>
              {subCategory.children_categories &&
                subCategory.children_categories.length > 0 &&
                subCategory.children_categories.map(childSubCategory => (
                  <option
                    key={childSubCategory.category_id}
                    value={childSubCategory.category_id}
                    style={{
                      fontWeight:
                        childSubCategory.children_categories &&
                        childSubCategory.children_categories.length > 0
                          ? "bold"
                          : "normal",
                    }}
                    disabled={
                      childSubCategory.children_categories &&
                      childSubCategory.children_categories.length > 0
                    }
                  >
                    {" "}
                    &nbsp; &nbsp; &nbsp; {childSubCategory.category_name}{" "}
                    {childSubCategory.children_categories &&
                      childSubCategory.children_categories.length > 0 && (
                        <span className="icon-down" aria-hidden="true">
                          ↓
                        </span>
                      )}
                  </option>
                ))}
            </>
          ))}
      </optgroup>
    ))
  }
  const requestNewDesigner = designerName => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/reqeust-new-designer`,
      { designer_name: designerName },
      { withCredentials: true }
    )
  }
  const handleAddDesigner = async () => {
    try {
      await requestNewDesigner(newDesignerName)
      toast.success("Başarıyla yeni tasarımcı talebi oluşturuldu!")
      setModalVisible(false)
      setNewDesignerName("")
    } catch (error) {
      toast.error("Tasarımcı eklenirken bir hata oluştu.")
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <form
          onSubmit={e => {
            e.preventDefault()
            mutatePart1.mutate()
          }}
        >
          <Container fluid style={{ marginBottom: "30px" }}>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Ürünler" breadcrumbItem="Ürün Ekle" />
            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6" md="12" sm="12">
                      <Row>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Label htmlFor="productname">Ürün adı*</Label>
                            <Input
                              id="productname"
                              name="productname"
                              type="text"
                              className="form-control"
                              placeholder="Ürün adı"
                              style={textFieldStyle}
                              value={productName}
                              required
                              onChange={e => {
                                setProductName(e.target.value)
                              }}
                            />
                            {part1Errors.productName !== "" ? (
                              <Form.Text style={{ color: "red" }}>
                                {part1Errors.productName}
                              </Form.Text>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="features">Özellikler</Label>
                          <textarea
                            className="form-control mb-3"
                            id="features"
                            // rows="3"
                            placeholder="Buraya yaz..."
                            style={textFieldStyle}
                            value={productFeatures}
                            required
                            onChange={e => {
                              setProductFeatures(e.target.value)
                            }}
                          />
                          {part1Errors.productFeatures !== "" ? (
                            <Form.Text style={{ color: "red" }}>
                              {part1Errors.productFeatures}
                            </Form.Text>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3 hashtag-input">
                          <Label className="control-label">Hashtags</Label>
                          <div
                            className="p-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              overflow: "auto ",
                              backgroundColor: "#F3F3F9",
                              border: "1px solid #ced4da",
                              borderRadius: "4px",
                            }}
                          >
                            <div className="tags d-flex wrap">
                              {hashtags.map((hashtag, index) => (
                                <Tag
                                  key={index}
                                  tag={hashtag}
                                  remove={() => handleHashtagRemove(hashtag)}
                                />
                              ))}
                            </div>
                            <Input
                              id="hashtag"
                              name="hashtag"
                              type="text"
                              className="form-control"
                              placeholder="Hashtag girip ENTER'a basınız"
                              value={hashtagInput}
                              onChange={handleHashtagInputChange}
                              onKeyDown={handleHashtagInputKeyDown}
                              style={{
                                border: "none",
                                backgroundColor: "#F3F3F9",
                              }}
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label htmlFor="productdesc">Ürün Açıklaması*</Label>
                          {/* <div className="quill"> 
                            <ReactQuill
                              theme="snow"
                              value={productDesc}
                              onChange={setProductDesc}
                              placeholder="Buraya yaz..."
                              style={textFieldStyle}
                              modules={modules} // Set the modules prop
                              formats={formats}
                            />
                          </div> */}

                          <textarea
                            className="form-control mb-3"
                            placeholder="Buraya yaz..."
                            style={{ ...textFieldStyle, height: "104.5px" }}
                            value={productDesc}
                            required
                            onChange={e => {
                              setProductDesc(e.target.value)
                            }}
                          />
                          {part1Errors.productDesc !== "" ? (
                            <Form.Text style={{ color: "red" }}>
                              {part1Errors.productDesc}
                            </Form.Text>
                          ) : null}
                        </div>
                      </Row>
                      <Label className="control-label mt-2">
                        Tasarımcı (Opsiyonel)
                      </Label>
                      {isDesignersLoading ? (
                        <p>Tasarımcılar yükleniyor...</p>
                      ) : (
                        <div className="flex">
                          <select
                            value={selectedDesignerId}
                            onChange={e =>
                              setSelectedDesignerId(e.target.value)
                            }
                            className="flex mr-2 border border-gray-300 rounded p-2 w-full select2"
                            style={{ ...textFieldStyle, width: "90%" }}
                          >
                            <option value="" disabled>
                              Tasarımcı Seçiniz
                            </option>
                            {designersData?.data?.map(designer => (
                              <option
                                key={designer.designer_id}
                                value={designer.designer_id}
                              >
                                {designer.designer_name}
                              </option>
                            ))}
                          </select>
                          <Button
                            type="primary"
                            onClick={() => setModalVisible(true)}
                            className="p-2 ms-1"
                            style={{ width: "7%" }}
                          >
                            +
                          </Button>
                        </div>
                      )}
                      <Modal
                        title="Yeni Tasarımcı Talebi"
                        visible={modalVisible}
                        onOk={handleAddDesigner}
                        onCancel={() => setModalVisible(false)}
                        okText="Yeni Tasarımcı Talebi"
                        cancelText="İptal Et"
                        width="30%"
                      >
                        <Input
                          value={newDesignerName}
                          onChange={e => setNewDesignerName(e.target.value)}
                          placeholder="Tasarımcı adını giriniz..."
                        />
                      </Modal>
                    </Col>
                    <Col
                      lg="6"
                      md="12"
                      sm="12"
                      className="d-flex flex-column gap-3"
                    >
                      <div className="mb-3">
                        <Label
                          className="control-label"
                          style={{ display: "block", marginBottom: "5px" }}
                        >
                          Kategori*
                        </Label>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <select
                            required
                            value={categoryId}
                            onChange={handleCategoryChange} 
                            className="form-control select2"
                            style={{ ...textFieldStyle, flex: 1 }} 
                          >
                            <option value="">Kategori Seçiniz</option>
                            {categoryData?.data &&
                              renderCategories(categoryData.data)}
                          </select>

                          {/* Edit Iconu */}
                          {showEditButton && (
                             <Button
                             type="primary"
                             style={{ display: "flex", alignItems: "center",marginLeft:"5px" }}
                             onClick={() => setIsModalVisible(true)}
                           >
                             <EditOutlined style={{ marginRight: "5px" }} />
                             Düzenle
                           </Button>
                          )}
                        </div>
                      </div>

                      <Modal
                        title={
                          <span
                            style={{ fontSize: "22px", fontWeight: "bold" }}
                          >
                            Ürünün Özelliklerini Seçiniz
                          </span>
                        }
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={null}
                        width="55%"
                        bodyStyle={{
                          maxHeight: "60vh",
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        {isAttributesLoading ? (
                          <div>
                            <Spin size="large" />
                            <span> Attributeler yükleniyor...</span>
                          </div>
                        ) : (
                          <div style={{ padding: "10px" }}>
                            <span
                              style={{ fontSize: "17px", fontWeight: "bold" }}
                            >
                              {" "}
                              Zorunlu Özellikler
                            </span>
                            {attributeData?.data
                              ?.filter(attribute => attribute.is_required === 1)
                              .reduce((rows, attribute, index) => {
                                if (index % 2 === 0) {
                                  rows.push([attribute])
                                } else {
                                  rows[rows.length - 1].push(attribute)
                                }
                                return rows
                              }, [])
                              .map((row, rowIndex) => (
                                <Row key={rowIndex} className="mt-3">
                                  {row.map(attribute => (
                                    <Col key={attribute.attribute_id} lg="6">
                                      <div>
                                        <Label>
                                          {attribute.attribute_name}{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </Label>
                                        <Select
                                          placeholder={`${attribute.attribute_name}`}
                                          style={{ width: "100%" }}
                                          onChange={value => {
                                            setSelectedValues(prev => ({
                                              ...prev,
                                              [attribute.attribute_id]: value,
                                            }))
                                          }}
                                        >
                                          {attribute.attribute_values.map(
                                            value => (
                                              <Select.Option
                                                key={value.attribute_value_id}
                                                value={value.attribute_value_id}
                                              >
                                                {value.attribute_value}
                                              </Select.Option>
                                            )
                                          )}
                                        </Select>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              ))}
                            <br />
                            <span
                              style={{ fontSize: "17px", fontWeight: "bold" }}
                            >
                              {" "}
                              Opsiyonel Özellikler
                            </span>
                            {attributeData?.data
                              ?.filter(attribute => attribute.is_required !== 1)
                              .reduce((rows, attribute, index) => {
                                if (index % 2 === 0) {
                                  rows.push([attribute])
                                } else {
                                  rows[rows.length - 1].push(attribute)
                                }
                                return rows
                              }, [])
                              .map((row, rowIndex) => (
                                <Row key={rowIndex} className="mt-3">
                                  {row.map(attribute => (
                                    <Col key={attribute.attribute_id} lg="6">
                                      <div>
                                        <Label>
                                          {attribute.attribute_name}
                                        </Label>
                                        <Select
                                          placeholder={`${attribute.attribute_name}`}
                                          style={{ width: "100%" }}
                                          onChange={value => {
                                            setSelectedValues(prev => ({
                                              ...prev,
                                              [attribute.attribute_id]: value,
                                            }))
                                          }}
                                        >
                                          {attribute.attribute_values.map(
                                            value => (
                                              <Select.Option
                                                key={value.attribute_value_id}
                                                value={value.attribute_value_id}
                                              >
                                                {value.attribute_value}
                                              </Select.Option>
                                            )
                                          )}
                                        </Select>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              ))}
                          </div>
                        )}
                      </Modal>

                      {part1Errors?.categoryId && (
                        <Form.Text style={{ color: "red" }}>
                          {part1Errors.categoryId}
                        </Form.Text>
                      )}

                      <Row>
                        <Col xs="6">
                          <div className="mb-3">
                            <Label htmlFor="price">Marka*</Label>
                            <Input
                              id="productbrand"
                              name="productbrand"
                              type="text"
                              className="form-control"
                              placeholder="Marka"
                              style={textFieldStyle}
                              value={productBrand}
                              required
                              onChange={e => {
                                setProductBrand(e.target.value)
                              }}
                            />
                            {part1Errors.productBrand !== "" ? (
                              <Form.Text style={{ color: "red" }}>
                                {part1Errors.productBrand}
                              </Form.Text>
                            ) : null}
                          </div>
                        </Col>
                        <Col xs="6">
                          <div className="mb-3">
                            <Label htmlFor="manufacturerbrand">
                              Üretici Markası*
                            </Label>
                            <Input
                              id="manufacturerbrand"
                              name="manufacturerbrand"
                              type="text"
                              className="form-control"
                              placeholder="Üretici Markası"
                              style={textFieldStyle}
                              value={producManufacturerBrand}
                              required
                              onChange={e => {
                                setProducManufacturerBrand(e.target.value)
                              }}
                            />
                            {part1Errors.manufacturerBrand !== "" ? (
                              <Form.Text style={{ color: "red" }}>
                                {part1Errors.manufacturerBrand}
                              </Form.Text>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="mb-3 color-input">
                        <Label className="control-label">Renkler*</Label>
                        <div
                          className="p-2  "
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            overflow: "auto ",
                            alignItems: "center",
                            backgroundColor: "#F3F3F9",
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                          }}
                        >
                          <div
                            className="colors d-flex "
                            style={{ flexWrap: "wrap" }}
                          >
                            {colors.map((color, index) => (
                              <Tag
                                key={index}
                                color={color.color_hex}
                                type="color"
                                tag={color.color_name}
                                change={e =>
                                  handleColorChange(e, color.color_name)
                                }
                                remove={() =>
                                  handleColorRemove(color.color_name)
                                }
                              />
                            ))}
                          </div>
                          <Input
                            id="color"
                            name="color"
                            type="text"
                            className="form-control"
                            placeholder="Renk ismi girip ENTER'a basınız"
                            value={colorInput}
                            onChange={handleColorInputChange}
                            onKeyDown={handleColorInputKeyDown}
                            style={{
                              border: "none",
                              backgroundColor: "#F3F3F9",
                              resize: "vertical",
                            }}
                          />
                        </div>
                        {part1Errors.color !== "" ? (
                          <Form.Text style={{ color: "red" }}>
                            {part1Errors.color}
                          </Form.Text>
                        ) : (
                          <>
                            <Form.Text muted>
                              Rengi Değiştirmek İçin Rengin{" "}
                              <strong>Üzerine Tıklayabilirsiniz</strong>.
                            </Form.Text>{" "}
                            <br></br>
                            <Form.Text muted>
                              Renk İsmi Girip <strong>ENTER</strong>'a basınız
                            </Form.Text>
                          </>
                        )}
                        <br></br>
                        {part1Errors.colorEnter !== "" ? (
                          <Form.Text style={{ color: "red" }}>
                            {part1Errors.colorEnter}
                          </Form.Text>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Row>
                          <Col xs="2">
                            <Label className="control-label">Bedenler*</Label>
                          </Col>
                        </Row>
                        <div
                          className="p-2"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            backgroundColor: "#F3F3F9",
                            border: "1px solid #ced4da",
                            borderRadius: "4px",
                            overflow: "auto ",
                          }}
                        >
                          <div
                            className="sizes d-flex "
                            style={{ flexWrap: "wrap" }}
                          >
                            {sizes.map((size, index) => (
                              <Tag
                                key={index}
                                tag={size.size}
                                remove={() => handleSizeRemove(size.size)}
                              />
                            ))}
                          </div>
                          <Input
                            id="size"
                            name="size"
                            type="text"
                            className="form-control"
                            placeholder="Beden ismi girip ENTER'a basınız"
                            value={sizeInput}
                            onChange={handleSizeInputChange}
                            onKeyDown={handleSizeInputKeyDown}
                            style={{
                              border: "none",
                              backgroundColor: "#F3F3F9",
                            }}
                          />
                        </div>
                        {part1Errors.size !== "" ? (
                          <Form.Text style={{ color: "red" }}>
                            {part1Errors.size}
                          </Form.Text>
                        ) : (
                          <Form.Text muted>
                            Beden İsmi Girip <strong>ENTER</strong> 'a Basınız
                          </Form.Text>
                        )}
                        <br></br>
                        {part1Errors.sizeEnter !== "" ? (
                          <Form.Text style={{ color: "red" }}>
                            {part1Errors.sizeEnter}
                          </Form.Text>
                        ) : null}
                      </div>

                      <div className="mt-1 ms-1">
                        <Label className="control-label mt-2">
                          Hızlı Teslimat
                        </Label>{" "}
                        <br />
                        <div className="mt-2 mb-3">
                          <Switch
                            checked={switchActive}
                            onChange={checked => setSwitchActive(checked)}
                          />
                          <span
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              textAlign: "center",
                            }}
                          >
                            Ürün hızlı teslimat özelliğine sahiptir.
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>

            <Row>
              <Col
                xs="12"
                className="d-flex flex-column justify-content-space-between"
              >
                <Row>
                  {sizes.length > 0 && colors.length > 0 && (
                    <Card>
                      <CardBody>
                        <div style={{ overflowX: "auto" }}>
                          {" "}
                          {/* Yatay kaydırma için */}
                          <table style={{ width: "100%" }}>
                            {" "}
                            {/* Tabloyu genişlikte esnet */}
                            <thead>
                              <tr>
                                <th>Renk</th>
                                <th className="d-flex align-items-center">
                                  <Input
                                    type="checkbox"
                                    onChange={() => {}}
                                    checked={selectAllChecked}
                                    onClick={handleSelectAllSizes}
                                    className=" p-0 m-1"
                                  />
                                  Beden
                                </th>
                                <th>Barcode</th>
                                <th>KDV</th>
                                <th>ButikO Fiyatı</th>
                                <th>ButikO Dışındaki Fiyat</th>
                                <th>Miktar</th>
                                <th>Eylem</th>
                              </tr>
                            </thead>
                            <tbody style={{ border: "1px solid #C7C7C7" }}>
                              {products?.map((value, index) => (
                                <React.Fragment key={index}>
                                  {value.sizes.map((size, sizeIndex) => (
                                    <tr
                                      style={{
                                        border: "2px solid #a1a1a1",
                                        margin: "10px",
                                      }}
                                      key={sizeIndex}
                                    >
                                      {sizeIndex === 0 && (
                                        <>
                                          <td
                                            rowSpan={value.sizes.length}
                                            style={{
                                              width: "100px",
                                              border: "1px solid #C7C7C7",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                width: "16px",
                                                height: "16px",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  value.color.color_hex,
                                                boxShadow:
                                                  "2px 2px 3px 0px #cfcfcf",
                                                marginLeft: "8px",
                                              }}
                                            ></div>
                                            <span
                                              className="ms-2 text-truncate"
                                              style={{
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                maxWidth: "60px",
                                              }}
                                            >
                                              {value.color.color_name}
                                            </span>
                                          </td>
                                        </>
                                      )}
                                      <td className="ps-2">
                                        <Row>
                                          <Col
                                            xs="2"
                                            className="p-0 ms-2 d-flex justify-content-center align-items-center"
                                            style={{ marginRight: "8px" }}
                                          >
                                            <Input
                                              type="checkbox"
                                              onChange={() => {}}
                                              checked={rows.some(
                                                row =>
                                                  row[0] === index &&
                                                  row[1] === sizeIndex
                                              )}
                                              onClick={e =>
                                                handleCheckboxChange(
                                                  index,
                                                  sizeIndex,
                                                  e
                                                )
                                              }
                                              className="form-control p-0 m-0"
                                            />
                                          </Col>
                                          <Col xs="auto" className="p-0">
                                            <p
                                              style={{ maxWidth: "45px" }}
                                              className="mb-0 text-truncate"
                                            >
                                              {size.size}
                                            </p>
                                          </Col>
                                        </Row>
                                      </td>
                                      <td>
                                        <Input
                                          type="text"
                                          name="barcode"
                                          value={size.barcode}
                                          onChange={e =>
                                            handleInputChange(
                                              index,
                                              sizeIndex,
                                              e
                                            )
                                          }
                                          placeholder="Barcode"
                                          required
                                        />
                                        <span style={{ color: "red" }}>
                                          {
                                            errors[index]?.[sizeIndex]?.[
                                              "barcode"
                                            ]
                                          }
                                        </span>
                                      </td>
                                      <td>
                                        <Input
                                          type="select"
                                          name="tax_rate"
                                          value={size.tax_rate}
                                          onChange={e =>
                                            handleInputChange(
                                              index,
                                              sizeIndex,
                                              e
                                            )
                                          }
                                          required
                                        >
                                          <option>KDV</option>
                                          <option value="0">0</option>
                                          <option value="1">1</option>
                                          <option value="10">10</option>
                                          <option value="20">20</option>
                                        </Input>
                                        <span style={{ color: "red" }}>
                                          {
                                            errors[index]?.[sizeIndex]?.[
                                              "tax_rate"
                                            ]
                                          }
                                        </span>
                                      </td>
                                      <td>
                                        <Input
                                          type="number"
                                          name="price_in_butiko"
                                          placeholder="butiko'daki fiyat"
                                          value={size.price_in_butiko}
                                          required
                                          onChange={e =>
                                            handleInputChange(
                                              index,
                                              sizeIndex,
                                              e
                                            )
                                          }
                                        />
                                        <span style={{ color: "red" }}>
                                          {
                                            errors[index]?.[sizeIndex]?.[
                                              "price_in_butiko"
                                            ]
                                          }
                                        </span>
                                      </td>
                                      <td>
                                        <Input
                                          type="number"
                                          name="price_outside_butiko"
                                          placeholder="butiko dışındaki fiyat"
                                          value={size.price_outside_butiko}
                                          onChange={e =>
                                            handleInputChange(
                                              index,
                                              sizeIndex,
                                              e
                                            )
                                          }
                                          required
                                        />
                                        <span style={{ color: "red" }}>
                                          {
                                            errors[index]?.[sizeIndex]?.[
                                              "price_outside_butiko"
                                            ]
                                          }
                                        </span>
                                      </td>
                                      <td>
                                        <Input
                                          type="number"
                                          placeholder="miktar"
                                          name="quantity"
                                          value={size.quantity}
                                          onChange={e =>
                                            handleInputChange(
                                              index,
                                              sizeIndex,
                                              e
                                            )
                                          }
                                          required
                                        />
                                        <span style={{ color: "red" }}>
                                          {
                                            errors[index]?.[sizeIndex]?.[
                                              "quantity"
                                            ]
                                          }
                                        </span>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        <TiDelete
                                          style={{
                                            color: "rgb(250, 126, 126)",
                                            fontSize: "28px",
                                          }}
                                          onClick={() =>
                                            handleDeleteSize(index, sizeIndex)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </Row>

                <div className="flex-grow-1"></div>
                <Row>
                  <div className="d-flex flex-wrap justify-content-end gap-2 p-3">
                    <Button
                      onClick={() => {
                        window.history.back()
                      }}
                      type="button"
                      color="secondary"
                    >
                      Geri Git
                    </Button>
                    <Button type="submit" color="success">
                      Yeni Ürün Oluştur
                    </Button>
                    <UploadMediaModal
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      products={products}
                      setProducts={setProducts}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            {/* <ToastContainer/> */}
          </Container>
        </form>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct

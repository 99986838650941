import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Button, Input, Label } from "reactstrap"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import CategoryFilter from "./CategoryFilter"
import "./products.css"
import { Switch } from 'antd';

// Import Query
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// Import Helpers
import * as url from 'helpers/url_helper';
import { get, post, put, del } from "helpers/api_helper"

const FilterSection = ({ setNextButtonDisabled, setErrorOccurred, currentPage, setProducts, refetch }) => {

  const [filterApplied, setFilterApplied] = useState(false)
  const [filters, setFilters] = useState({
    search: "",
    priceRange: [0, 10000],
    selectedSubCategory: null,
    rating: 0,
    showPendingProducts:false,
  });

  const [isRatingsSelected, setIsRatingsSelected] = useState([false, false, false, false])

  // To update a specific filter:
  const updateFilter = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const resetFilters = () => {
    setFilters({
      search: "",
      priceRange: [0, 10000],
      selectedSubCategory: null,
      rating: 0,
      showPendingProducts:false,
    })
    setIsRatingsSelected([false, false, false, false, false])
  }

  // Define event handlers outside JSX and pass them directly
  const onChange = (checked) => {
    // console.log(`switch to ${checked}`);
    updateFilter('showPendingProducts', checked)
    setFilterApplied(false);
    applyFiltersHandler()
  };
  const handleSearchChange = (e) => updateFilter('search', e.target.value);
  const handleSliderChange = (newRange) => updateFilter('priceRange', newRange);
  const handleRatingChange = (value) => {
    const updatedRating = filters.rating === value ? 0 : value; // Toggle the rating

    updateFilter('rating', updatedRating);
    setFilterApplied(true);
  };

  const handleSubCategoryChange = (value) => updateFilter('selectedSubCategory', value);

  const refetchProducts = () => {
    refetch();
    setFilterApplied(false);
    setErrorOccurred(false);
    setNextButtonDisabled(false);
    resetFilters();
  };

  const applyFilters = async () => {

    const { search, priceRange, selectedSubCategory, rating, showPendingProducts } = filters;

    const startingPrice = priceRange[0]
    const endPrice = priceRange[1]

    const postData = {
      // Add starting_price if startingPrice is truthy
      ...(startingPrice && { starting_price: startingPrice }),

      // Add end_price if endPrice is truthy
      ...(endPrice && { end_price: endPrice }),

      // Sayfa numarasını doğru şekilde ayarlandı
      page: currentPage,

      // Add category_id if selectedSubCategory is truthy
      ...(selectedSubCategory && { category_id: selectedSubCategory }),

      // Add rating if rating is truthy
      ...(rating && { rating }),

      // Add search if searchValue is truthy and has a non-zero length
      ...(search && search.length && { search: search }),
      ...(showPendingProducts && {show_pending_products: showPendingProducts})
    };
    // console.log(postData);

    const response = await post(url.POST_PRODUCT_SEARCH, postData, { withCredentials: true })

    // console.log(response);

    const responseData = response.map(item => ({
      ...item,
      media_links: item.media_links,
      colors: item.colors,
      sizes: item.sizes,
    }));

    setProducts(responseData);

    return responseData
  }

  const applyFiltersMutation = useMutation({
    mutationKey: ['applyFilters'],
    mutationFn: applyFilters,
    onError: (error) => {
      if (error?.response?.data?.detail?.[0]?.msg === "unathorized action" || error?.response?.data?.detail?.[0]?.msg === "invalid jwt") {
        navigate("/login")
      }

      if (error?.response?.data?.detail?.[0]?.msg === "product not found") {
        setNextButtonDisabled(true)
        setFilterApplied(true)
      } else {
        setNextButtonDisabled(false)
        setFilterApplied(false);
      }

      // console.log(error);
      setErrorOccurred(true)
    },
    onSuccess: (data, variables) => {
      // // Log the received data to verify
      // console.log(data);

      // // Set the products data with the filtered data in the cache
      // queryClient.setQueryData(['getProducts', currentPage], data);

      setProducts(data)

      // Reset other state variables if needed
      setFilterApplied(true);
      setErrorOccurred(false);
      setNextButtonDisabled(false);
    },
  });

  const applyFiltersHandler = async () => {
    try {
      await applyFiltersMutation.mutateAsync(); // Use mutateAsync to await the mutation
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const anySelection = filters.priceRange[0] !== 0 || filters.priceRange[1] !== 10000;
    if (anySelection) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [filters.priceRange]);

  useEffect(() => {
    // console.log(filters.rating);
  }, [filters])
  
  return (
    <Card>
      <CardBody className="p-0">
        <CardTitle className="mb-4">
          <i className="bx bx-filter"></i>Filtrele
        </CardTitle>

        <div>
          <div>
            <CategoryFilter
              onCategorySelect={(selectedCategory) => {
                handleSubCategoryChange(selectedCategory);
                setFilterApplied(true);
              }}
            />
          </div>

          <div className="mt-4 pt-3">
            <h5 className="font-size-14 mb-3">Fiyat</h5>
            <br />
            <div className="price-slider">
              <Slider
                range
                min={0}
                max={10000}
                step={100}
                value={filters.priceRange}
                onChange={handleSliderChange}
              />
              <div className="price-indicators">
                <span>{filters.priceRange[0]}₺</span>
                <span>{filters.priceRange[1]}₺</span>
              </div>
            </div>
          </div>

          <div className="mt-4 pt-3">
            <h5 className="font-size-14 mb-3">Müşteri Değerlendirmesi</h5>
            <Input
              type="select"
              value={filters.rating}
              onChange={(e) => {
                const selectedRating = parseInt(e.target.value);
                handleRatingChange(selectedRating);
                setFilterApplied(true);
              }}
            >
              {[4, 3, 2, 1].map((rating, i) => (
                <option value={rating} className="form-check-label" htmlFor={rating} key={i}>
                  {rating + " yıldız ve üzeri"}
                </option>

              ))}
            </Input>

          </div>
          <div className="mt-4 pt-3">
            <h5 className="font-size-14 me-2 d-inline-block">Onay Bekleyen Ürünleri Göster:</h5>
            <Switch value={filters.showPendingProducts} className=" float-end" onChange={onChange} />

          </div>

          <Button
            color="light"
            onClick={() => {
              applyFiltersHandler()
            }}
            className="FilterButton"
            disabled={!filterApplied}
          >
            Filtrele
          </Button>
          {filterApplied && (
            <Button
              color="light"
              onClick={refetchProducts}
              className="FilterButton"
            >
              Tüm Verileri Göster
            </Button>
          )}
        </div>
      </CardBody>
    </Card >
  );
};

export default FilterSection;

// TotalPurchasesCard.js
import React from "react";
import { Card, CardBody } from "reactstrap";
import { MdOutlineShoppingCart } from "react-icons/md";

const TotalPurchasesCard = ({ totalPurchases }) => {
  const valueStyle = { fontSize: "19.5px", fontWeight: "500" };
  const statStyle = { color: "#74788D" };

  return (
    <Card className="mini-stats-wid position-relative">
      <CardBody>
        <div className="d-flex align-items-center pb-3">
          <div className="avatar-xs rounded-circle bg-primary align-self-center mini-stat-icon">
            <span className="avatar-title rounded-circle bg-primary font-size-16">
              <MdOutlineShoppingCart />
            </span>
          </div>
          <div className="flex-grow-1">
            <p className="text-muted fw-medium px-2 m-0" style={{ fontSize: "14px", fontWeight: 500, lineHeight: "21px", color: "#495057" }}>Toplam Satın Alımlar</p>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 ">
        <p className="m-0 py-2" style={statStyle}>Sipariş Sayısı : </p>
          <p className="m-0 py-2" style={valueStyle}>{totalPurchases?.times ? totalPurchases.times : 0}</p>
          
        </div>
        <div className="d-flex align-items-center gap-2 ">
        <p className="m-0 py-2" style={statStyle}>Kazanılan Toplam Tutar : </p>
          <p className="m-0 py-2" style={valueStyle}>{totalPurchases?.total_value ? totalPurchases.total_value : 0}₺</p>
        </div>
      </CardBody>
    </Card>
  );
};

export default TotalPurchasesCard;

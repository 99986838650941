import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useBarcode = (orderItemId) => {
    const getBarcode = async () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/get-barcode?order_item_id=${orderItemId}&option=shipping_reference_no`;
        const response = await axios.get(finalUrl, { withCredentials: true });
        return response;
    };

    const {
        isLoading: barcodeIsLoading,
        isError: barcodeIsError,
        error: barcodeError,
        data: barcodeData,
        refetch: barcodeRefetch
    } = useQuery({queryKey:['barcode', orderItemId],queryFn: getBarcode,enabled: orderItemId !==undefined});

    return {
        barcodeIsLoading,
        barcodeIsError,
        barcodeError,
        barcodeData,
        barcodeRefetch
    };
};
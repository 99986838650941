import React from "react"
import { Link } from "react-router-dom"
import ColorCircle from "../../ColorCircle"
import { Button } from "reactstrap"
import { FaBan, FaTrashAlt } from "react-icons/fa"
import { LiaEdit } from "react-icons/lia"
import Dropdown from "react-bootstrap/Dropdown"
import { FaEdit } from "react-icons/fa"
import { RiFileEditFill } from "react-icons/ri"
import { TbSettingsPlus } from "react-icons/tb"
import usePromotionsModalStore from "pages/Ecommerce/EcommerceProducts/productStore"
import { BiDetail } from "react-icons/bi"
import { MdDeleteOutline } from "react-icons/md"
import { IoBagRemoveOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { Badge } from "react-bootstrap"
import { Tooltip } from 'antd';

const ProductRow = ({
  product,
  openOutOfStockModal,
  openDeleteConfirmModal,
  setSelectedProductId,
  setProductName,
}) => {
  const navigate = useNavigate()
  const { isOpen, openModal, closeModal, toggleModal } =
    usePromotionsModalStore()
  const sizeBoxStyle = {
    border: "1px solid #a2a2a2",
    padding: "4px 7px",
    margin: "0 3px 5px 3px",
    display: "inline-block",
    borderRadius: "10px",
  }

  const overflowStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }

  const handleOutOfStock = () => {
    setSelectedProductId(product.product_id)
    setProductName(product.product_name)
    openOutOfStockModal()
  }

  const handleDeleteProduct = () => {
    setSelectedProductId(product.product_id)
    setProductName(product.product_name)
    openDeleteConfirmModal()
  }
  console.log(product);
  return (
    <tr>
      {/* <td className={`text-center`}>
        <Link to={"/ecommerce-product-detail/" + product.product_id}>
          {product.media_links.length > 0 && (
            <img
              alt={`Image 0`}
              src={product.media_links[0]}
              style={{
                width: "150px",
                height: "200px",
                objectFit: "cover",
                border: "1px solid  #eee",
                borderRadius: "8px",
              }}
            />
          )}
        </Link>
      </td> */}
      <td className={`text-center `}>
        <Link
          to={
            product?.status !== "staged"
              ? `/ecommerce-product-detail/${product.product_id}`
              : "#"
          }
        >
          {product.media_links.length > 0 && (
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                alt={`Image 0`}
                src={product.media_links[0]}
                className={`${product?.status == "staged" && "opacity-50"}`}
                style={{
                  width: "150px",
                  height: "200px",
                  objectFit: "cover",
                  border: "1px solid #eee",
                  borderRadius: "8px",
                }}
              />
              {product.status === "staged" && (
                <Badge
                  bg="warning"
                  style={{
                    position: "absolute",
                    top: "8px",
                    left: "8px",
                    opacity: "1 !important",
                  }}
                >
                  Onaylanmayı Bekliyor
                </Badge>
              )}
            </div>
          )}
        </Link>
      </td>
      {/* Diğer ürün bilgileri */}

      <td
        className={`align-middle ${product?.status == "staged" && "opacity-50"
          }`}
        style={{ fontWeight: 500, backgroundColor: "#fff" }}
      >
        <span
          style={{ lineHeight: "21px", fontSize: "14px", color: "#343A40" }}
        >
          {product.product_name}
        </span>
        <br></br>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {product?.colors ? (
            <>
              {product?.colors?.slice(0, 3).map((color, index) => (
                <ColorCircle key={index} color={color.color_hex} />
              ))}
              {product?.colors?.length > 3 && (
                <Tooltip
                  title={product.colors
                    .slice(3)
                    .map(color => color.color_name)
                    .join(', ')}
                >
                  <div
                    style={{ ...sizeBoxStyle, ...overflowStyle, marginBottom: 0 }}
                  >
                    <span style={{ fontSize: "16px" }}>
                      + {product.colors.length - 3}
                    </span>
                  </div>
                </Tooltip>
              )}
            </>
          ) : (
            <span style={{ fontSize: "13.5px" }}>Renk bulunamadı</span>
          )}
        </div>
        <span style={{ fontSize: "13.5px" }}>
          {product?.sizes ? (
            product.sizes.length > 3 ? (
              <>
                {product.sizes.slice(0, 3).map((size, index) => (
                  <span key={index} style={sizeBoxStyle}>
                    {size}
                  </span>
                ))}
                <span style={sizeBoxStyle}>
                  +{product.sizes.length - 3} daha
                </span>
              </>
            ) : (
              product.sizes.map((size, index) => (
                <span key={index} style={sizeBoxStyle}>
                  {size}
                </span>
              ))
            )
          ) : (
            <span style={{ fontSize: "13.5px" }}>Beden bulunamadı</span>
          )}
        </span>
      </td>

      <td
        className={`text-center align-middle ${product?.status == "staged" && "opacity-50"
          }`}
        style={{ backgroundColor: "#fff" }}
      >
        <span style={{ fontSize: "13.5px" }}>{product.purchases ?? 0}</span>
      </td>
      <td
        className={`text-center align-middle ${product?.status == "staged" && "opacity-50"
          }`}
        style={{ backgroundColor: "#fff" }}
      >
        <span style={{ fontSize: "13.5px" }}>{product.quantity ?? 0}</span>
      </td>
      <td
        className={`text-center align-middle ${product?.status == "staged" && "opacity-50"
          }`}
        style={{ backgroundColor: "#fff" }}
      >
        <span style={{ fontSize: "13.5px" }}>
          {product.average_price ?? "Bilgi bulunamadı"}₺
        </span>
        <br></br>
      </td>
      {/*  */}
      <td
        className={`text-center align-middle ${product?.status == "staged" && "opacity-50"
          }`}
        style={{ backgroundColor: "#fff" }}
      >
        <span style={{ fontSize: "13.5px" }}>
          {product.rating ?? "Bilgi bulunamadı"}
        </span>
        <br></br>
      </td>
      {/*  */}
      <td
        className={`text-center align-middle ${product?.status == "staged" && "opacity-50"
          }`}
        style={{ backgroundColor: "#fff" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Link  to={"/ecommerce-product-detail/" + product.product_id}> */}
          <Button
            type="button"
            disabled={product?.status === "staged"}
            style={{
              border: "none",
              backgroundColor: "#fff",
            }}
            className="btn d-flex flex-column align-items-center"
            onClick={() => {
              openModal()
              navigate("/ecommerce-product-detail/" + product.product_id)
            }}
          >
            <FaEdit
              className="fa-lg"
              style={{ color: "#556ee6", fontSize: "25px" }}
            />
            <span className="extend-span" style={{ color: "#556ee6" }}>
              Düzenle
            </span>
          </Button>
          {/* </Link> */}
          <Dropdown>
            <Dropdown.Toggle
              disabled={product?.status === "staged"}
              variant="secondary"
              id="dropdown-basic"
              className=" d-flex flex-column align-items-center bg-transparent text-secondary border-0"
            >
              <TbSettingsPlus
                className="fa-lg"
                style={{ marginBottom: "5px", fontSize: "25px" }}
              />
              Diğer İşlemler
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Link to={"/ecommerce-product-detail/" + product.product_id} style={{ color: "black" }}> */}
              <Dropdown.Item
                onClick={() => {
                  navigate("/ecommerce-product-detail/" + product.product_id)
                }}
                className="d-flex ps-3 align-items-center"
              >
                <BiDetail className="me-1" size={16} />
                Ürün Detaylarına Git
              </Dropdown.Item>
              {/* </Link> */}
              <Dropdown.Item
                className="d-flex ps-3 align-items-center"
                onClick={handleOutOfStock}
              >
                <IoBagRemoveOutline className="me-1" size={16} />
                Stoktan Kaldır
              </Dropdown.Item>
              <Dropdown.Item
                className="d-flex ps-3 align-items-center"
                onClick={handleDeleteProduct}
              >
                <MdDeleteOutline className="me-1" size={16} />
                Ürünü Sil
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
      {/* <td className="d-flex align-middle justify-content-center">
        <button>sdf</button>
      </td> */}
      {/* <td style={{ backgroundColor: "#fff", height:"100%"}}>
        <div className="d-flex" style={{
          alignItems: "center",
          justifyContent:"center"
        }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Button
              type="button"
              style={{
                border: "none",
                backgroundColor: "#fff",
              }}
              className="btn d-flex flex-column align-items-center"
              onClick={handleOutOfStock}
            >
              <FaBan className="fa-lg" style={{ color: "#A54611" }} />
              <span className="extend-span" style={{ color: "#A54611" }}>
                Stoktan Kaldır
              </span>
            </Button>
          </div>
           <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
         
            }}
          >
            <Button
              type="button"
              style={{
                border: "none",
                backgroundColor: "#fff",
              }}
              className="btn d-flex flex-column align-items-center"
              onClick={handleOutOfStock}
            >
              <FaBan className="fa-lg" style={{ color: "#A54611" }} />
              <span className="extend-span" style={{ color: "#A54611" }}>
                Stoktan Kaldır
              </span>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Button
              onClick={handleDeleteProduct}
              style={{
                border: "none",
                backgroundColor: "#fff",
              }}
              className="btn d-flex flex-column align-items-center"
            >
              <LiaEdit  className="text-success fa-lg" />
              <span className="extend-span text-danger">Ürünü Düzenle</span>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Button
              onClick={handleDeleteProduct}
              style={{
                border: "none",
                backgroundColor: "#fff",
              }}
              className="btn d-flex flex-column align-items-center"
            >
              <FaTrashAlt className="text-danger fa-lg" />
              <span className="extend-span text-danger">Ürünü Kaldır</span>
            </Button>
          </div>
        </div>
      </td> */}
    </tr>
  )
}

export default ProductRow

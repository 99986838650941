import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  CardText,
  TabContent,
  TabPane,
  Input,
} from "reactstrap"
import "react-step-progress-bar/styles.css"
import "./Register.css"
import Bank from "./Components/banka/Bank"
import { useNavigate } from "react-router-dom"
import Loading2 from "./SVG/Loading2"
import { del, get, post, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import classnames from "classnames"
import ErrorDisplay from "./Components/hataGöster/ErrorDisplay"
import SelectComponent from "./Components/şirketTürü/SelectComponent"
import AddressInputComponent from "./Components/adres/AddressInputComponent"
import { useForm } from "./FormContext"
import { useErrorContext } from "./ErrorContext"
import Kvkk from "pages/sozlesmeler/kvkk.js"
import AcikRizaMetni from "pages/sozlesmeler/acikRizaMetni.js"
import { ToastContainer, toast } from "react-toastify"
import {
  firstPageErrorMessages,
  secondPageErrorMessages,
  addressErrorMessages,
} from "./Components/hataMesajlari/errorMessages"
import RegisterSuccessPopup from "./Popups/RegisterSuccessPopup"
import KullanimSozlesmesi from "pages/sozlesmeler/kullanimSozlesmesi"
import { CheckBoxSection } from "./Components/onayCheckbox/CheckBoxSection"
import FormInput from "./Components/formInput/FormInput"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { Tooltip } from "antd"
import { IoArrowBackCircleSharp, IoCheckmarkCircle } from "react-icons/io5"
import { RiErrorWarningFill } from "react-icons/ri"
import Warnings from "./Components/Warnings"
import { FaQuestion } from "react-icons/fa6"
import HelpManangety from "./Popups/HelpManangety"

const Register = () => {
  // ! Kayıt olma verilerini daha rahat toplamak için oluşturduğum useForm hooku.
  const { formData, updateFormData } = useForm()

  // ! Register 1 ve Register 2 sayfalarındaki tüm hataları daha rahat yönetmek için oluşturduğum ErrorContext.
  // * errors ile kullanılacak hata seçiliyor.
  // * showError input verilen hatayı gösteriyor.
  // * hideError metodu input verilen hatayı saklıyor.
  // * clearErrors ise tüm hataları temizliyor.
  const { errors, showError, hideError, clearErrors } = useErrorContext()

  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleClose = () => {
    setIsModalVisible(false)
  }
  const isAuth = () => {
    return axios.get(url.GET_IS_AUTH, { withCredentials: true })
  }
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ["is-auth"],
    queryFn: isAuth,
  })
  if (isLoading) {
  } else if (isError) {
  } else {
    if (data?.data?.is_authenticated) {
      navigate("/")
    }
  }
  const [registerSuccessModalOpen, setRegisterSuccessModalOpen] =
    useState(false)

  // Şirket türü için oluşturulan Enum.
  const CompanyType = {
    SAHIS: "sahis",
    ADI_ORTAKLIK: "adi_ortaklik",
    KOLEKTIF_SIRKET: "kolektif_sirket",
    ADI_KOMANDIT_SIRKET: "adi_komandit_sirket",
    SERMAYESI_PAYLARA_BOLUNMUS_KOMANDIT_SIRKET:
      "sermayesi_paylara_bolunmus_komandit_sirket",
    LIMITED_SIRKET: "limited_sirket",
    ANONIM_SIRKET: "anonim_sirket",
    KOOPERATIF_SIRKET: "kooperatif_sirket",
    DIGER_IS_ORTAKLIGI: "diger_is_ortakligi",
    DERNEK_ISLETMESI: "dernek_isletmesi",
    VAKIF_ISLETMESI: "vakif_isletmesi",
  }

  // İş metodu için oluşturulan Enum
  const BusinessMethod = {
    ONLY_ONLINE: "only-online",
    ONLINE_AND_IN_STORE: "online-and-in-store",
  }

  // * Veri tutmak için oluşturulan tüm hooklar
  // ! Çok fazla var! Sadeleştirilmesi lazım. Ama Zustand'de ayarlanır.
  const [companyType, setCompanyType] = useState("")
  const [companyTitle, setCompanyTitle] = useState("")
  const [registrationNumber, setRegistrationNumber] = useState("")
  const [mersisNumber, setMersisNumber] = useState("")
  const [shopName, setShopName] = useState("")
  const [businessMethod, setBusinessMethod] = useState("")
  const [companyTaxOffice, setCompanyTaxOffice] = useState("")
  const [companyTaxNumber, setCompanyTaxNumber] = useState("")
  const [bankIBAN, setBankIBAN] = useState("")
  const [bankName, setBankName] = useState("")
  const [bankBranch, setBankBranch] = useState("")

  useEffect(() => {
    setBusinessMethod(BusinessMethod.ONLY_ONLINE)
  }, [])

  const [isMultipleAddress, setIsMultipleAddress] = useState(false)

  // * Adresler için useState değişkenleri
  const [officeAddress, setOfficeAddress] = useState({
    city: "",
    district: "",
    neighborhood: "",
    street: "",
    apartment_no: "",
    house_no: "",
    address: "",
    lat: 0,
    lng: 0,
  })
  const [storeAddress, setStoreAddress] = useState({
    city: "",
    district: "",
    neighborhood: "",
    street: "",
    apartment_no: "",
    house_no: "",
    address: "",
    lat: 0,
    lng: 0,
  })

  // * İşaretleme kutucukları için stateler.
  const [kullanimCheckbox, setKullanimCheckbox] = useState(false)
  const [kvkkCheckbox, setKvkkCheckbox] = useState(false)
  const [acikCheckbox, setAcikCheckbox] = useState(false)

  // ! Bu kısmı sırf veriler sayfa yenilenince kalsın diye yapmıştım.
  // ! 1. sayfa ile aynı şekilde bu kısmı da Local Storage'da saklamalıyız.
  // ! Bu bütün useEffect kısmı silinip Zustang PERSIST MIDDLEWARE kullanılmalı!
  useEffect(() => {
    setCompanyType((formData.company_type || "").toString())
    setCompanyTitle((formData.company_title || "").toString())
    setRegistrationNumber((formData.company_registration_no || "").toString())
    setMersisNumber((formData.company_mersis_no || "").toString())
    setShopName((formData.shop_name || "").toString())
    setIsMultipleAddress(formData.is_multiple_address || false)

    setOfficeAddress(
      formData.office_address || {
        city: "",
        district: "",
        neighborhood: "",
        street: "",
        apartment_no: "",
        house_no: "",
        address: "",
        lat: 0,
        lng: 0,
      }
    )

    setStoreAddress(
      formData.store_address || {
        city: "",
        district: "",
        neighborhood: "",
        street: "",
        apartment_no: "",
        house_no: "",
        address: "",
        lat: 0,
        lng: 0,
      }
    )

    setCompanyTaxOffice((formData.company_tax_office || "").toString())
    setCompanyTaxNumber((formData.company_tax_no || "").toString())
    setBankIBAN((formData.company_bank_IBAN || "").toString())
    setBankName((formData.company_bank_name || "").toString())
    setBankBranch((formData.company_bank_branch || "").toString())
  }, [formData])

  // * Adres tab state'i ve sözleşme popuplarının açılıp kapanması için stateler.
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [modalKullanimShow, setModalKullanimShow] = useState(false)
  const [modalKvkkShow, setModalKvkkShow] = useState(false)
  const [modalAcikRizaShow, setModalAcikRizaShow] = useState(false)

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  // * Tüm handleChange metodları. Hepsi sadece tüm input kutucuklarını doldurmaya ve hataları gizlemeye yarıyor.
  const handleCompanyTypeChange = event => {
    const selectedValue = event.target.value
    setCompanyType(selectedValue)
    hideError("companyType")
  }
  const handleTitleChange = event => {
    setCompanyTitle(event.target.value)
    hideError("title")
  }

  // Sicil numarası değişikliği.
  // * En fazla 7 sayı yazmaya izin veriyor. 7 hane olunca başka yazamıyor.
  const handleRegistrationNumberChange = event => {
    let inputValue = event.target.value.replace(/\D/g, "") // Remove non-digit characters

    // Automatically add hyphen after the sixth digit
    if (inputValue.length > 6) {
      inputValue = inputValue.slice(0, 6) + "-" + inputValue.slice(6)
    }

    // Ensure the total length does not exceed 8 characters (6 digits + hyphen + 1 digit)
    if (inputValue.length <= 8) {
      setRegistrationNumber(inputValue)
    }

    hideError("registrationNumber")
  }

  // Mersis numarası değişikliği.
  // * En fazla 16 sayı yazmaya izin veriyor. 16 hane olunca başka yazamıyor.
  const handleMersisNumberChange = event => {
    const inputValue = event.target.value
    if (/^\d*$/.test(inputValue) || inputValue === "") {
      if (inputValue.length <= 16) {
        setMersisNumber(inputValue)
      }
      hideError("mersisNumber")
    }
  }

  const handleShopNameChange = event => {
    setShopName(event.target.value)
    hideError("shopName")
  }

  const handleCompanyTaxOfficeChange = event => {
    setCompanyTaxOffice(event.target.value)
    hideError("taxOffice")
  }

  // Vergi numarası değişikliği.
  // * En fazla 11 sayı yazmaya izin veriyor. 11 hane olunca başka yazamıyor.
  const handleCompanyTaxNumberChange = event => {
    const inputValue = event.target.value
    if (/^\d*$/.test(inputValue) || inputValue === "") {
      if (inputValue.length <= 11) {
        setCompanyTaxNumber(inputValue)
      }
      hideError("taxNumber")
    }
  }

  const handleCompanyTaxNumberBlur = () => {
    if (companyTaxNumber.length < 10 || companyTaxNumber.length > 11) {
      showError("taxNumber", "Vergi numarası 10 veya 11 haneli olmalıdır")
    } else {
      hideError("taxNumber")
    }
  }

  const handleBusinessMethodChange = event => {
    const selectedValue = event.target.value
    setBusinessMethod(selectedValue)
    if (selectedValue === BusinessMethod.ONLY_ONLINE) {
      setIsMultipleAddress(false)
      toggleCustom("1")
    }
  }

  const handleIsMultipleAddress = event => {
    setIsMultipleAddress(event.target.checked)
    if (!event.target.checked) {
      toggleCustom("1")
    }
  }

  // Tarayıcıda geri tuşuna  önceki sayfaya gidiyor. O da register-1 sayfası.
  const handleBackButtonClick = () => {
    navigate(-1)
  }

  const openRegisterSuccessPopup = () => {
    setRegisterSuccessModalOpen(true)
  }

  // ! Bu metod sırf sistemde önceden bir aynı veri varsa onu kontrol ediyor ve hata dönderiyor.
  // * Şirket adı, dükkan ady ve vergi numarası aynı olamaz.

  // !!! Bu metod handleRegister'da kullanılıyor. HandleRegister ise handleFinishButtonClick metodunda kullanılıyor. Kısacası burası EPEY karışık.

  const checkForDuplicates = async () => {
    const datas = [
      {
        option: "company_title",
        value: companyTitle,
        role: "seller",
      },
      {
        option: "shop_name",
        value: shopName,
        role: "seller",
      },
      {
        option: "company_tax_no",
        value: companyTaxNumber,
        role: "seller",
      },
    ]

    // * Eğer şirket türü şahıs değilse ve kayıt no ile mersis numarası boş değilse,
    // * Şirket kayıt numarası ve mersis numaralarının da kontrol edilmesi gerekiyor.
    if (
      companyType !== CompanyType.SAHIS &&
      registrationNumber !== "" &&
      mersisNumber !== ""
    ) {
      datas.push({
        option: "company_registration_no",
        value: registrationNumber,
      })

      datas.push({
        option: "company_mersis_no",
        value: mersisNumber,
      })
    }

    // * BankaIBANı boş değilse bunu da datas arrayine ekliyoruz. Çünkü iki kullanıcıda aynı iban olmamalı.
    if (bankIBAN && bankIBAN !== "") {
      datas.push({
        option: "company_bank_IBAN",
        value: bankIBAN,
      })
    }

    // ! Datas array'ine eklediğimiz tüm veriler için duplicate API'sine ayrı ayrı istek atıyoruz.
    // * Bütün bu istekler ayrı ayrı responselar olarak responses arrayine geliyor.

    const responses = await Promise.all(
      datas.map(async data => {
        try {
          const response = await post(url.POST_DUPLICATE_INFORMER, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })

          return response
        } catch (error) {
          console.error(error)

          // ! Uzun mu uzun bir error handling kodu. Bunun da basitleştirilmesi gerekiyor.
          if (error) {
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.detail) {
                  if (error.response.data.detail[0]) {
                    if (error.response.data.detail[0].msg) {
                      // if (
                      //   error.response.data.detail[0].msg ===
                      //   "company_title duplication"
                      // ) {
                      //   showError(
                      //     "title",
                      //     secondPageErrorMessages.duplicateCompanyTitleMsg
                      //   )
                      // }
                      // if (
                      //   error.response.data.detail[0].msg ===
                      //   "company_registration_no duplication"
                      // ) {
                      //   showError(
                      //     "registrationNumber",
                      //     secondPageErrorMessages.duplicateRegistrationNumber
                      //   )
                      // }
                      // if (
                      //   error.response.data.detail[0].msg ===
                      //   "company_mersis_no duplication"
                      // ) {
                      //   showError(
                      //     "mersisNumber",
                      //     secondPageErrorMessages.duplicateMersisNoMsg
                      //   )
                      // }
                      if (
                        error.response.data.detail[0].msg ===
                        "shop_name duplication"
                      ) {
                        showError(
                          "shopName",
                          secondPageErrorMessages.duplicateMagazaMsg
                        )
                      }
                      // if (
                      //   error.response.data.detail[0].msg ===
                      //   "company_tax_no duplication"
                      // ) {
                      //   showError(
                      //     "taxNumber",
                      //     secondPageErrorMessages.duplicateVergiNoMsg
                      //   )
                      // }
                      if (
                        error.response.data.detail[0].msg ===
                        "company_bank_IBAN duplication"
                      ) {
                        showError(
                          "bankIBAN",
                          secondPageErrorMessages.duplicateBankIBAN
                        )
                      }
                    }
                  }
                }
              }
            }
          }

          throw error
        }
      })
    )

    console.log(responses)
  }

  function convertAndCapitalize(str) {
    return str.toUpperCase()
  }
  function convertAndCapitalizeDistrict(str) {
    str = str.replace(/I/gi, "ı")
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  // !! Bu metod checkForDuplicates metodunu kullanıyor.
  const handleRegister = async () => {
    try {
      const updatedFormData = {
        ...formData,
        company_type: companyType,
        ...(companyType !== CompanyType.SAHIS && {
          company_registration_no: registrationNumber,
          company_mersis_no: mersisNumber,
        }),
        shop_name: shopName,
        company_title: companyTitle,
        business_method: businessMethod,
        is_multiple_address: isMultipleAddress,
        office_address: {
          ...officeAddress,
          city: convertAndCapitalize(officeAddress.city),
          district: convertAndCapitalizeDistrict(officeAddress.district),
        },
        ...(isMultipleAddress && {
          store_address: {
            ...storeAddress,
            city: convertAndCapitalize(storeAddress.city),
            district: convertAndCapitalizeDistrict(storeAddress.district),
          },
        }),
        company_tax_office: companyTaxOffice,
        company_tax_no: companyTaxNumber,
        ...(bankName &&
          bankName.length !== 0 && { company_bank_name: bankName }),
        ...(bankBranch &&
          bankBranch.length !== 0 && { company_bank_branch: bankBranch }),
        ...(bankIBAN &&
          bankIBAN.length !== 0 && { company_bank_IBAN: bankIBAN }),
      }

      if (!isMultipleAddress) {
        delete updatedFormData.storeAddress
      }

      console.log(updatedFormData)

      await checkForDuplicates()

      const response = await post(url.POST_AUTH_REGISTER, updatedFormData, {
        headers: { "Content-Type": "application/json" },
      })

      console.log(response)

      if (response && response.info === "email sent") {
        openRegisterSuccessPopup()
        updateFormData({})
      }
    } catch (error) {
      console.log(error)
      console.error("Error occured while registering: ", error.response.data)

      const errorDetail = error.response.data.detail

      const errorMap = {
        "seller_TC_no duplication": [
          "tcNo",
          "Sistemde kayıtlı olan bir TC kimlik numarası girdiniz.",
          "/register",
        ],
        "The email address is not valid. It must have exactly one @-sign.": [
          "email",
          firstPageErrorMessages.emailForgottenSign,
          "/register",
        ],
        "invalid phone number format phonenumber": [
          "phoneNumber",
          "Lütfen telefon numaranızı başında sıfır olmadan, aralarında boşluk olmadan 5554443322 formatında giriniz.",
          "/register",
        ],
        "phone_number duplication": [
          "phoneNumber",
          firstPageErrorMessages.duplicatePhoneNumber,
          "/register",
        ],
        "company_title duplication": [
          "title",
          secondPageErrorMessages.duplicateCompanyTitleMsg,
        ],
        "mersis no duplication": [
          "mersisNumber",
          secondPageErrorMessages.duplicateMersisNoMsg,
        ],
        "shop_name duplication": [
          "shopName",
          secondPageErrorMessages.duplicateMagazaMsg,
        ],
        "company_tax_no duplication": [
          "taxNumber",
          secondPageErrorMessages.duplicateVergiNoMsg,
        ],
        "email duplication": [
          "email",
          firstPageErrorMessages.duplicateEmail,
          "/register",
        ],
        "companyTitle, registration_number and mersis_number must be specified for non-personal company type owners":
          ["title", secondPageErrorMessages.unvanEmptyMsg],
        "invalid office address city or district name": [
          "officeAddress",
          secondPageErrorMessages.invalidOfficeAddressCity,
        ],
        "invalid store address city or district name": [
          "storeAddress",
          secondPageErrorMessages.invalidStoreAddressCity,
        ],
        "String should have at least 1 character": [
          "iban",
          secondPageErrorMessages.emptyIban,
        ],
        "Value error, company_bank_IBAN must match following pattern: TR<24 digit>":
          ["iban", secondPageErrorMessages.incorrectIban],
        "Value error, invalid specification for lat and lng values": [
          "address",
          secondPageErrorMessages.adresMsg,
        ],
        missing: {
          first_name: [
            "firstName",
            firstPageErrorMessages.firstName,
            "/register",
          ],
          last_name: ["lastName", firstPageErrorMessages.lastName, "/register"],
          seller_TC_no: ["tcNo", firstPageErrorMessages.tcNo, "/register"],
          email: ["email", firstPageErrorMessages.emailAddress, "/register"],
          password: ["password", firstPageErrorMessages.password, "/register"],
          companyTitle: ["title", secondPageErrorMessages.unvanMsg],
          registration_number: [
            "registrationNumber",
            secondPageErrorMessages.sicilMsg,
          ],
          mersis_number: ["mersisNumber", secondPageErrorMessages.mersisMsg],
          shop_name: ["shopName", secondPageErrorMessages.magazaMsg],
          company_address: ["address", secondPageErrorMessages.adresMsg],
          tax_office: ["taxOffice", secondPageErrorMessages.vergiDaireMsg],
          tax_no: ["taxNumber", secondPageErrorMessages.vergiNoMsg],
        },
      }

      const handleError = errorDetail => {
        if (Array.isArray(errorDetail)) {
          errorDetail.forEach(err => {
            const errorType = errorMap[err.msg] || errorMap.missing[err.loc[1]]
            if (errorType) {
              const [field, message, redirect] = errorType
              showError(field, message)
              if (redirect) navigate(redirect)
            }
          })
        } else {
          alert("Something went wrong.")
        }
      }

      handleError(errorDetail)
    }
  }

  // !! Bu metod ve validateInputs ve handleRegister metodlarını kullanıyor.
  // !!!!! VE BU METOD DİREKT ONAYLA/FINISH BUTONUNA BASINCA ÇALIŞIYOR.
  const handleFinishButtonClick = async () => {
    clearErrors()

    if (validateInputs()) {
      try {
        await handleRegister()
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              console.error(
                "Error occured while registering: ",
                error.response.data
              )
            }
          }
        }
      }
    }
  }
  const handleTabSwitch = tabId => {
    const isOfficeAddressComplete =
      officeAddress.city &&
      officeAddress.district &&
      officeAddress.neighborhood &&
      officeAddress.street &&
      officeAddress.apartment_no &&
      officeAddress.house_no &&
      officeAddress.address

    const isStoreAddressComplete =
      storeAddress.city &&
      storeAddress.district &&
      storeAddress.neighborhood &&
      storeAddress.street &&
      storeAddress.apartment_no &&
      storeAddress.house_no &&
      storeAddress.address

    if (tabId === "2" && !isOfficeAddressComplete) {
      toast.error("Lütfen önce mağaza adresinizi giriniz.", {
        toastId: "descriptionMutationStore",
      })
      return
    } else if (tabId === "1" && isMultipleAddress && !isStoreAddressComplete) {
      toast.error("Lütfen ofis adresinizi tamamlayınız.", {
        toastId: "descriptionMutationOffice",
      })
      return
    }

    toggleCustom(tabId)
  }

  // ! Çok ama çok karışık bir inputlardaki hataları kontrol etme metodu.
  // * Tüm hatalar ayrı şekilde kontrol edilmeli
  // ! Ama daha önemlisi tüme hatalar için isValid KULLANILMAMALI!
  function validateInputs() {
    let isValid = true

    if (!companyType) {
      showError("companyType", secondPageErrorMessages.companyType)
      isValid = false
    }

    if (companyType !== CompanyType.SAHIS) {
      isValid =
        validateInputField(
          "registrationNumber",
          registrationNumber,
          /^\d{6}-\d{1}$/,
          secondPageErrorMessages.sicilMsg,
          secondPageErrorMessages.sicilEmptyMsg
        ) && isValid
      isValid =
        validateInputField(
          "mersisNumber",
          mersisNumber,
          /^\d{16}$/,
          secondPageErrorMessages.mersisMsg
        ) && isValid
    }

    isValid =
      validateInputField(
        "title",
        companyTitle,
        /^[A-Za-zğüşıöçĞÜŞİÖÇ\s]+$/,
        secondPageErrorMessages.unvanMsg,
        secondPageErrorMessages.unvanEmptyMsg
      ) && isValid
      isValid =
      validateInputField(
        "shopName",
        shopName,
        /^[A-Za-zğüşıöçĞÜŞİÖÇ0-9\s]+$/, 
        secondPageErrorMessages.magazaMsg
      ) && isValid;
    
    isValid =
      validateInputField(
        "address",
        officeAddress,
        undefined,
        secondPageErrorMessages.adresMsg
      ) && isValid

    isValid =
      validateInputField(
        "iban",
        bankIBAN,
        /^TR\d{24}$/,
        secondPageErrorMessages.incorrectIban
      ) && isValid

    checkAddressError(officeAddress, addressErrorMessages)

    const isStoreAddressComplete =
      storeAddress.city &&
      storeAddress.district &&
      storeAddress.neighborhood &&
      storeAddress.street &&
      storeAddress.apartment_no &&
      storeAddress.house_no &&
      storeAddress.address

    if (isMultipleAddress && !isStoreAddressComplete) {
      toast.error("Lütfen ofis adresinizi doldurunuz", {
        toastId: "noneDescriptionMutationOffice",
      })
      isValid = false
    }

    isValid =
      validateInputField(
        "taxOffice",
        companyTaxOffice,
        /^[A-Za-zğüşıöçĞÜŞİÖÇ\s]+$/,
        secondPageErrorMessages.vergiDaireMsg
      ) && isValid
    isValid =
      validateInputField(
        "taxNumber",
        companyTaxNumber,
        /^\d{10,11}$/,
        secondPageErrorMessages.vergiNoMsg
      ) && isValid

    isValid =
      validateCheckbox(
        "kullanimCheckbox",
        kullanimCheckbox,
        secondPageErrorMessages.kullanimMsg
      ) && isValid
    isValid =
      validateCheckbox(
        "kvkkCheckbox",
        kvkkCheckbox,
        secondPageErrorMessages.kvkkMsg
      ) && isValid
    isValid =
      validateCheckbox(
        "acikCheckbox",
        acikCheckbox,
        secondPageErrorMessages.acikMsg
      ) && isValid

    return isValid
  }

  // * validateInputs metodundaki karışıklığı azaltmak için yazılan bir metod.
  // * Input kutularında hata varsa gösteriyor, yoksa saklıyor ve isValid için true veya false dönüyor.
  function validateInputField(
    fieldName,
    fieldValue,
    regex,
    errorMsg,
    emptyErrorMsg = errorMsg
  ) {
    if (regex && !regex.test(fieldValue)) {
      showError(fieldName, errorMsg)
      return false
    } else if (fieldValue === "") {
      showError(fieldName, emptyErrorMsg)
      return false
    }

    hideError(fieldName)
    return true
  }
  // * validateInputs metodundaki karışıklığı azaltmak için yazılan bir metod.
  // * Fakat bu metod ise işaretleme kutucukları için.
  function validateCheckbox(fieldName, isChecked, errorMsg) {
    if (!isChecked) {
      showError(fieldName, errorMsg)
      return false
    }
    hideError(fieldName)
    return true
  }

  // * Bu metod da aynı şekilde validateInputs metodundaki karışıklığı azaltmak için yazılan bir metod.
  // * Bu ise adres hataları için.
  const checkAddressError = (address, addressErrorMessages) => {
    if (!address.city) {
      toast.error(addressErrorMessages.city, {
        toastId: "descriptionMutationOffice",
      })
      showError("city", addressErrorMessages.city)
    } else {
      hideError("city")
    }
    if (!address.district) {
      showError("district", addressErrorMessages.district)
    } else {
      hideError("district")
    }

    if (!address.neighborhood) {
      showError("neighborhood", addressErrorMessages.neighborhood)
    } else {
      hideError("neighborhood")
    }

    if (!address.street) {
      showError("street", addressErrorMessages.street)
    } else {
      hideError("street")
    }

    if (!address.apartment_no) {
      showError("apartmentNo", addressErrorMessages.apartmentNo)
    } else {
      hideError("apartmentNo")
    }

    if (!address.house_no) {
      showError("houseNo", addressErrorMessages.houseNo)
    } else {
      hideError("houseNo")
    }

    if (!address.address) {
      showError("address", addressErrorMessages.address)
    } else {
      hideError("address")
    }
  }

  const companyOptions = [
    { value: CompanyType.SAHIS, label: "Şahıs" },
    {
      value: CompanyType.ADI_ORTAKLIK,
      label: "Adi Ortaklık",
    },
    {
      value: CompanyType.KOLEKTIF_SIRKET,
      label: "Kolektif Şirket",
    },
    {
      value: CompanyType.ADI_KOMANDIT_SIRKET,
      label: "Adi Komandit Şirket",
    },
    {
      value: CompanyType.SERMAYESI_PAYLARA_BOLUNMUS_KOMANDIT_SIRKET,
      label: "Sermayesi Paylara Bölünmüş Komandit Şirket",
    },
    {
      value: CompanyType.LIMITED_SIRKET,
      label: "Limited Şirket",
    },
    {
      value: CompanyType.ANONIM_SIRKET,
      label: "Anonim Şirket",
    },
    {
      value: CompanyType.KOOPERATIF_SIRKET,
      label: "Kooperatif Şirket",
    },
    {
      value: CompanyType.DIGER_IS_ORTAKLIGI,
      label: "Diğer İş Ortaklığı",
    },
    {
      value: CompanyType.DERNEK_ISLETMESI,
      label: "Dernek İşletmesi",
    },
    {
      value: CompanyType.VAKIF_ISLETMESI,
      label: "Vakıf İşletmesi",
    },
  ]

  return (
    <div className="Register">
      <Row>
        <Col
          lg="2"
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <Loading2 />
        </Col>
        <Col lg="10">
          <h2 className="title d-flex align-items-center gap-2">
            <IoArrowBackCircleSharp
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer", color: "#a0a0a0" }}
            />
            Satıcı Kaydı
          </h2>
          <span className="subTitle">Şirket Bİlgileri</span>
          <Row style={{ marginTop: "50px" }}>
            <Col lg="6">
              <div style={{ display: "flex" }}>
                <span className="inputTitle">Şirket Tipi</span>
                <Warnings
                  title={
                    "Şirket türü, tüzel kişilik, yükümlülük ve sermaye yapısına göre değişir."
                  }
                />
              </div>
              <Row style={{ marginTop: "5px" }}>
                <Col lg="12">
                  <Col lg="10">
                    <SelectComponent
                      value={companyType}
                      onChange={handleCompanyTypeChange}
                      options={companyOptions}
                      placeholder="Şirket Türü Seç"
                    />
                    <ErrorDisplay error={errors.companyType} />
                  </Col>
                </Col>
              </Row>

              <Row style={{ marginTop: "20px" }} className="unvan-row">
                <Col lg="10">
                  <span className="inputTitle">Unvan</span>
                  <Row style={{ marginTop: "5px" }}>
                    <Col lg="12">
                      <div>
                        <input
                          type="text"
                          value={companyTitle}
                          onChange={handleTitleChange}
                          className="form-control form-text"
                          placeholder="Ünvan gir"
                          style={{ padding: "20px" }}
                        />
                        <ErrorDisplay error={errors.title} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* 951. satırdan 1081. satıra kadar sırf sıra sıra input kutuları var.
              Şimdi bunlar için bir bileşen yazacağım. */}
              {/* Tamamdır şimdi daha iyi*/}
              <Row style={{ marginTop: "20px" }}>
                {companyType !== CompanyType.SAHIS && (
                  <>
                    <FormInput
                      title="Sicil numarası"
                      value={registrationNumber}
                      onChange={handleRegistrationNumberChange}
                      placeholder="Sicil numara girin"
                      error={errors.registrationNumber}
                      lg={5}
                    />
                    <FormInput
                      title="Mersis numarası"
                      value={mersisNumber}
                      onChange={handleMersisNumberChange}
                      placeholder="Mersis no gir"
                      error={errors.mersisNumber}
                      lg={5}
                    />
                  </>
                )}
              </Row>

              <FormInput
                title="Mağaza adı"
                value={shopName}
                onChange={handleShopNameChange}
                placeholder="Mağaza adı gir"
                error={errors.shopName}
              />
              <FormInput
                title="Vergi dairesi"
                value={companyTaxOffice}
                onChange={handleCompanyTaxOfficeChange}
                placeholder="Vergi dairesi girin"
                error={errors.taxOffice}
              />
              <FormInput
                title="Vergi numarası"
                value={companyTaxNumber}
                onChange={handleCompanyTaxNumberChange}
                onBlur={handleCompanyTaxNumberBlur}
                placeholder="Vergi numarası girin"
                error={errors.taxNumber}
              />

              <Row style={{ marginTop: "20px" }}>
                <Col lg="10">
                  <span className="inputTitle">Banka IBAN </span>
                  <Bank
                    iban={bankIBAN}
                    setIban={setBankIBAN}
                    name={bankName}
                    setName={setBankName}
                    branch={bankBranch}
                    setBranch={setBankBranch}
                  />

                  <ErrorDisplay error={errors.bankIBAN} />
                </Col>
              </Row>
            </Col>

            {/* İş yöntemi radyo butonları */}
            <Col lg="5">
              <Row style={{ marginTop: "5px" }}>
                <Col lg="10">
                  <Row>
                    <Col lg="7">
                      <span className="inputTitle">İş Yöntemi</span>
                    </Col>
                    <Col lg="5">
                      <Row>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="link"
                            onClick={showModal}
                            style={{
                              backgroundColor: "#F8F8FB",
                              color: "#556EE6",
                              border: "none",
                            }}
                          >
                            İş Yönetimi Nedir
                            <FaQuestion
                              style={{
                                fontSize: "15px",
                                color: "#556EE6",
                                marginLeft: "10px",
                              }}
                            />
                          </Button>
                        </span>
                      </Row>
                    </Col>
                  </Row>
                  <HelpManangety
                    isVisible={isModalVisible}
                    onClose={handleClose}
                  />
                  <Row style={{ marginTop: "10px" }}>
                    <Col lg="12">
                      <div className="form-check form-radio-primary mb-3">
                        <input
                          className="form-check-input "
                          style={{ border: "1px #000 solid" }}
                          type="radio"
                          name="businessMethod"
                          id="businessRadio2"
                          value={BusinessMethod.ONLY_ONLINE}
                          onClick={handleBusinessMethodChange}
                          checked={
                            businessMethod === BusinessMethod.ONLY_ONLINE
                          }
                        />
                        <div style={{ display: "flex" }}>
                          <label
                            className="form-check-label me-1"
                            htmlFor="businessRadio2"
                          >
                            Yalnızca Butiko aracılığıyla çevrimiçi satış
                            yapıyorum
                          </label>
                        </div>
                      </div>
                      <div className="form-check form-check-primary mb-3">
                        <input
                          className="form-check-input"
                          style={{ border: "1px #000 solid" }}
                          type="radio"
                          name="businessMethod"
                          id="businessRadio1"
                          value={BusinessMethod.ONLINE_AND_IN_STORE}
                          onClick={handleBusinessMethodChange}
                          checked={
                            businessMethod ===
                            BusinessMethod.ONLINE_AND_IN_STORE
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="businessRadio1"
                        >
                          Hem çevrimiçi hem de mağaza da satış yapıyorum.
                        </label>
                      </div>
                      {businessMethod ===
                        BusinessMethod.ONLINE_AND_IN_STORE && (
                        <div className="form-check form-check-primary">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor5"
                            style={{ border: "1px #000 solid" }}
                            checked={isMultipleAddress}
                            onChange={handleIsMultipleAddress}
                          />
                          <div style={{ display: "flex" }}>
                            <label
                              className="form-check-label me-1"
                              htmlFor="customCheckcolor5"
                            >
                              Ofis adresiniz ve mağaza adresiniz farklı adresler
                              ise seçiniz
                            </label>
                            <Warnings
                              title={
                                "Kutucuğu işaretledikten sonra ilk önce mağaza adresinizi sonra ofis adresinizi doldurunuz !"
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Adres navbarları. Eğer isMultipleAddress doğru ise Mağaza adresi de görünüyor. */}
              <Row style={{ marginTop: "20px" }}>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <Col lg={isMultipleAddress ? "5" : "10"}>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => handleTabSwitch("1")}
                      >
                        <span className="inputTitle">Mağaza Adresi</span>
                      </NavLink>
                    </NavItem>
                  </Col>
                  {isMultipleAddress && (
                    <Col lg="5">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => handleTabSwitch("2")}
                        >
                          <span className="inputTitle">Ofis Adresi</span>
                        </NavLink>
                      </NavItem>
                    </Col>
                  )}
                </Nav>
                <TabContent
                  activeTab={customActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <AddressInputComponent
                      city={officeAddress.city}
                      setCity={value => {
                        setOfficeAddress(prev => ({ ...prev, city: value }))
                        hideError("city")
                      }}
                      district={officeAddress.district}
                      setDistrict={value => {
                        setOfficeAddress(prev => ({ ...prev, district: value }))
                        hideError("district")
                      }}
                      neighborhood={officeAddress.neighborhood}
                      setNeighborhood={value => {
                        setOfficeAddress(prev => ({
                          ...prev,
                          neighborhood: value,
                        }))
                        hideError("neighborhood")
                      }}
                      street={officeAddress.street}
                      setStreet={value => {
                        setOfficeAddress(prev => ({ ...prev, street: value }))
                        hideError("street")
                      }}
                      apartmentNo={officeAddress.apartment_no}
                      setApartmentNo={value => {
                        setOfficeAddress(prev => ({
                          ...prev,
                          apartment_no: value,
                        }))
                        hideError("apartmentNo")
                      }}
                      houseNo={officeAddress.house_no}
                      setHouseNo={value => {
                        setOfficeAddress(prev => ({ ...prev, house_no: value }))
                        hideError("houseNo")
                      }}
                      address={officeAddress.address}
                      setAddress={value => {
                        setOfficeAddress(prev => ({ ...prev, address: value }))
                        hideError("address")
                      }}
                      setLatitude={value =>
                        setOfficeAddress(prev => ({ ...prev, lat: value }))
                      }
                      setLongitude={value =>
                        setOfficeAddress(prev => ({ ...prev, lng: value }))
                      }
                      latt={officeAddress.lat}
                      lngg={officeAddress.lng}
                      inputTitle="office"
                      isMultipleAddress={isMultipleAddress}
                      cityError={errors.city}
                      districtError={errors.district}
                      neighborhoodError={errors.neighborhood}
                      streetError={errors.street}
                      apartmentNoError={errors.apartmentNo}
                      houseNoError={errors.houseNo}
                      addressError={errors.address}
                    />
                    {errors && errors.officeAddress && (
                      <ErrorDisplay error={errors.officeAddress} />
                    )}
                  </TabPane>
                  {isMultipleAddress && (
                    <TabPane tabId="2">
                      <AddressInputComponent
                        city={storeAddress.city}
                        setCity={value => {
                          setStoreAddress(prev => ({ ...prev, city: value }))
                          hideError("city")
                        }}
                        district={storeAddress.district}
                        setDistrict={value => {
                          setStoreAddress(prev => ({
                            ...prev,
                            district: value,
                          }))
                          hideError("district")
                        }}
                        neighborhood={storeAddress.neighborhood}
                        setNeighborhood={value => {
                          setStoreAddress(prev => ({
                            ...prev,
                            neighborhood: value,
                          }))
                          hideError("neighborhood")
                        }}
                        street={storeAddress.street}
                        setStreet={value => {
                          setStoreAddress(prev => ({ ...prev, street: value }))
                          hideError("street")
                        }}
                        apartmentNo={storeAddress.apartment_no}
                        setApartmentNo={value => {
                          setStoreAddress(prev => ({
                            ...prev,
                            apartment_no: value,
                          }))
                          hideError("apartmentNo")
                        }}
                        houseNo={storeAddress.house_no}
                        setHouseNo={value => {
                          setStoreAddress(prev => ({
                            ...prev,
                            house_no: value,
                          }))
                          hideError("houseNo")
                        }}
                        address={storeAddress.address}
                        setAddress={value => {
                          setStoreAddress(prev => ({ ...prev, address: value }))
                          hideError("address")
                        }}
                        latt={storeAddress.lat}
                        lngg={storeAddress.lng}
                        setLatitude={value =>
                          setStoreAddress(prev => ({ ...prev, lat: value }))
                        }
                        setLongitude={value =>
                          setStoreAddress(prev => ({ ...prev, lng: value }))
                        }
                        inputTitle="store"
                        isMultipleAddress={isMultipleAddress}
                        cityError={errors.city}
                        districtError={errors.district}
                        neighborhoodError={errors.neighborhood}
                        streetError={errors.street}
                        apartmentNoError={errors.apartmentNo}
                        houseNoError={errors.houseNo}
                        addressError={errors.address}
                      />
                      {errors && errors.storeAddress && (
                        <ErrorDisplay error={errors.storeAddress} />
                      )}
                    </TabPane>
                  )}
                </TabContent>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <CheckBoxSection
                  checked={kullanimCheckbox}
                  onChange={() => {
                    setKullanimCheckbox(prevState => !prevState)
                    hideError("kullanimCheckbox")
                  }}
                  buttonText="KULLANIM KOŞULLARI ve ÜYELİK SÖZLEŞMESİ"
                  onClick={() => setModalKullanimShow(true)}
                  ModalComponent={KullanimSozlesmesi}
                  modalShow={modalKullanimShow}
                  setModalShow={setModalKullanimShow}
                  error={errors.kullanimCheckbox}
                  buttonTextColor="#0075FF"
                />
                <CheckBoxSection
                  checked={kvkkCheckbox}
                  onChange={() => {
                    setKvkkCheckbox(prevState => !prevState)
                    hideError("kvkkCheckbox")
                  }}
                  buttonText="KVKK AYDINLATMA METNİ"
                  onClick={() => setModalKvkkShow(true)}
                  ModalComponent={Kvkk}
                  modalShow={modalKvkkShow}
                  setModalShow={setModalKvkkShow}
                  error={errors.kvkkCheckbox}
                />
                <CheckBoxSection
                  checked={acikCheckbox}
                  onChange={() => {
                    setAcikCheckbox(prevState => !prevState)
                    hideError("acikCheckbox")
                  }}
                  buttonText="AÇIK RIZA METNİ"
                  onClick={() => setModalAcikRizaShow(true)}
                  ModalComponent={AcikRizaMetni}
                  modalShow={modalAcikRizaShow}
                  setModalShow={setModalAcikRizaShow}
                  error={errors.acikCheckbox}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="button-container">
        {/* Geri ve Kaydol butonları ile beraber Onaylandı Popupı. Burada görünür bir sorun yok bence. */}
        <Row className="justify-content-end">
          <Col lg="auto" className="d-flex flex-column align-items-center">
            <button
              className="buttonNext"
              id="buttonNext"
              onClick={handleFinishButtonClick}
            >
              Kaydol
            </button>

            <RegisterSuccessPopup
              modalIsOpen={registerSuccessModalOpen}
              setIsOpen={setRegisterSuccessModalOpen}
            />
          </Col>
          <ToastContainer />
        </Row>
      </div>
    </div>
  )
}

export default Register

import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import DatePicker from "react-datepicker"
import "./invoices.css"
//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Slider from "rc-slider"
import axios from "axios"
import { useQuery, useMutation } from "@tanstack/react-query"
import { PiSmileySad } from "react-icons/pi";


const InvoicesList = () => {
  //meta title
  document.title = "Faturalar | ButikO"

  const [startDate, setStartDate] = useState(new Date())
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(0)
  // const [range, setRange] = useState("");
  const [type, setType] = useState("")
  const [priceRange, setPriceRange] = useState([0, 10000])

  const searchInvoice = () => {
    const startFormattedDate = startDate?.toISOString().split("T")[0]

    const finalUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/invoice/search`
    const finalData = {
      ...(type && { invoice_type: type }),
      ...(priceRange[1] !== 10000 && { max_amount: priceRange[1] }),
      ...(priceRange && { min_amount: priceRange[0] }),
      ...(startDate.toISOString().split("T")[0] !==
        new Date().toISOString().split("T")[0] && {
        created_at: startFormattedDate,
      }),
      ...(search && { invoice_id: search }),
      page: page,
    }
    console.log(finalData)
    return axios.post(finalUrl, finalData, { withCredentials: true })
  }
  const [invoiceData, setInvoiceData] = useState([])
  const [isNextPage, setIsNextPage] = useState(false)
  const [isInvoice, setIsInvoice] = useState(true)

  const [filterApplied, setFilterApplied] = useState(false)

  const searchInvoiceMutation = useMutation({
    mutationFn: searchInvoice,
    onSuccess: data => {
      console.log(data.data)
      setFilterApplied(false);
      if (data.data.length === 9) {
        const invoiceArray = data.data
        invoiceArray.pop()
        setInvoiceData(invoiceArray)
        setIsNextPage(true)
        setIsInvoice(true)

      } else {
        setIsNextPage(false)
        setInvoiceData(data.data)
      }
    },
    onError: error => {
      console.log(error)
      setIsInvoice(false)
      setFilterApplied(true)
    },
  })
  useEffect(() => {
    searchInvoiceMutation.mutate()
  }, [])

  useEffect(() => {
    if (
      startDate === new Date() &&
      search === "" &&
      type === "" &&
      priceRange === ([0, 10000])
    ) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }

  }, [startDate, search, type, priceRange])

  const handleFilterClick = () => {
    searchInvoiceMutation.mutate()
  }

  const handleShowAllProducts = () => {
    setPage(0);
    setSearch("");
    setStartDate(new Date());
    setType("");
    setPriceRange([0, 10000])
    setIsInvoice(true)
    setFilterApplied(false);
    searchInvoiceMutation.mutate()
  }

  const handleNextPage = page => {
    setPage(page => page + 1)
  }
  const handlePreviousPage = page => {
    setPage(page => page - 1)
  }
  const handleSliderChange = newRange => {
    setPriceRange(newRange)
    console.log(priceRange)
    setPage(0)
  }

  const handleDateChange = e => {
    console.log(e.target.value)
  }
  const handleTypeChange = event => {
    // const inputDate = new Date(event.target.value);
    if (event.target.checked) {
      setType("")
      setPage(0)
    } else {
      setType(event.target.value)
      setPage(0)
      console.log(event.target.value)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Siparişler" breadcrumbItem="Faturalar" />

          <Row>
            <Col xl={3} lg={3} md={12} sm={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Filtre</CardTitle>
                  <div
                    className="search-box me-2"
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid #CED4DA",
                      background: "#FFF",
                    }}
                  >
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control border-0"
                        placeholder="Id ye göre arama yapın..."
                        value={search}
                        onChange={e => {
                          setSearch(e.target.value)
                          setPage(0)
                        }}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5 className="font-size-14 mb-3">Oluşturulma Tarihi</h5>

                    <DatePicker
                      className="date-piker"
                      selected={startDate}
                      style={{ zIndex: 3 }}
                      onChange={date => {
                        setStartDate(date)
                        setPage(0)
                      }}
                    />
                  </div>

                  <div className="mt-4 pt-3">
                    <h5 className="font-size-14 mb-3">Fiyat</h5>
                    <br />

                    <Slider
                      range
                      min={0}
                      max={10000}
                      step={10}
                      value={priceRange}
                      onChange={handleSliderChange}
                      style={{ zIndex: 0 }}
                    />
                    <div className="price-indicators">
                      <span>₺{priceRange[0]}</span>
                      <span>₺{priceRange[1]}</span>
                    </div>
                  </div>
                  <div style={{ marginTop: "40px" }}>
                    <h5>Fatura Türü</h5>
                    {/* <form> */}
                    <div>
                      <input
                        type="radio"
                        id="token"
                        name="invoice-type"
                        checked={type === "token"}
                        value="token"
                        style={{ borderRadius: "10px" }}
                        onClick={handleTypeChange}
                      />
                      <label htmlFor="token" style={{ marginLeft: "10px" }}>
                        Token
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        checked={type === "commission"}
                        value="commission"
                        id="commission"
                        name="invoice-type"
                        onClick={handleTypeChange}
                      />
                      <label
                        htmlFor="commission"
                        style={{ marginLeft: "10px" }}
                      >
                        Komisyon
                      </label>
                    </div>



                    {/* </form> */}
                  </div>
                  <Row>
                    <Col>
                      <Button
                        color="light"
                        onClick={handleFilterClick}
                        className="FilterButton"
                      >
                        Filtrele
                      </Button>
                      {filterApplied && (<Button
                        color="light"
                        onClick={handleShowAllProducts}
                        className="FilterButton"
                      >
                        Tüm Verileri Göster
                      </Button>)}
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
            <Col xl={9} lg={9} md={12} sm={12}>
              <Row>
                {isInvoice ? (
                  invoiceData?.map(item => (
                    <Col
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      key={item.invoice_id}
                    >
                      <Card
                        style={{
                          borderLeft:
                            item.invoice_type === "token"
                              ? "4px solid #FFD999"
                              : "4px solid #B4FF99",
                          borderRadius: "4px",
                        }}
                      >
                        <CardBody>
                          <div className="d-flex justify-content-between">
                            <div>
                              <p>
                              Fatura Numarası :{" "}
                                <span
                                  style={{ fontSize: "15px", color: "#495057" }}
                                >
                                  {item.given_invoice_id}
                                </span>
                              </p>
                            </div>
                            <div className="d-flex justify-content-flex-start">
                              <i className="far fa-calendar-alt text-primary" />
                              <p className="ms-1">{item.created_at}</p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <p>
                              Fatura Tipi:{" "}
                              <span
                                className="ms-1"
                                style={{ fontSize: "15px", color: "#495057" }}
                              >
                                {item.invoice_type === "commission" ? ("Komisyon") : ("Token")}
                              </span>
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <div className="d-flex align-items-center">
                              <i className="far fa-money-bill-alt" />
                              <p className="mx-2 my-2">{item.amount}</p>
                            </div>
                            <div className="d-flex align-items-center">
                              <a
                                style={{
                                  border: "1px solid #556EE6",
                                  borderRadius: "4px",
                                }}
                                type="button"
                                download
                                className="btn btn-outline-primary d-flex align-items-center ms-1"
                                href={item.invoice_link}
                                target="blank"
                              >
                                <i className="fas fa-download me-2" />
                                Faturayı İndir
                              </a>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <>
                    <alert className="alert" style={{ background: "#EFF2F7", fontSize: "15px" }}>ButikO tarafından kesilmiş fatura bulunmamaktadır. </alert>

                  </>
                )}
              </Row>

              <Row>
                <Col className="d-flex justify-content-end">
                  {page > 0 && (
                    <Button className="prevButton" onClick={handlePreviousPage}>
                      Önceki
                    </Button>
                  )}
                  <Button
                    disabled={!isNextPage}
                    onClick={handleNextPage}
                    className={`nextButton${!isInvoice ? (" d-none ") : ("")}`}
                  >
                    Sonraki
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

export default withRouter(InvoicesList)

import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigationType } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Select } from 'antd';
import Button from 'react-bootstrap/Button';
import { Switch } from 'antd';
import { PiSortAscending } from "react-icons/pi";
import { PiSortDescending } from "react-icons/pi";
import {
  Col,
  Row,
  Card,
  CardBody
} from "reactstrap"
import "./main.css"
import Table from 'react-bootstrap/Table';
import axios from "axios"
import { useMutation } from "@tanstack/react-query"
import { Input } from 'antd';
import useOrderStore from "./orderStore";
import { Tooltip } from 'antd';
import { RiErrorWarningFill } from "react-icons/ri"
function EcommerceOrders() {
  const { Search } = Input;

  //meta title
  document.title = "Siparişler | Butiko"

  // const [allOrders, setAllOrders] = useState(orders)
  // const [order, setOrder] = useState("DESC")
  // const [sortBy, setSortBy] = useState("order_date");
  // const [page, setPage] = useState(0);
  // const [name, setName] = useState("");
  // const [showAll, setShowAll] = useState(false);
  const { page, sortBy, order, name, showAll } = useOrderStore((state) => state)
  const { setNextPage, setPrevPage, setPage, setSortBy, setOrder, setName, setShowAll } = useOrderStore((state) => state)
  // console.log({
  //   page: page,
  //   sorted_by: sortBy,
  //   asc_desc: order,
  //   name: name,
  //   showAll: showAll 
  // });
  // console.log(setPage,setSortBy,setOrder,setName,setShowAll);
  const handleChange = (value) => {
    setSortBy(value);
    setPage(0)
  };
  const onSearch = (value, _e, info) => {
    setName(value)
    searchOrderMutation.mutate();
  };
  const searchOrder = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/search-order`
    const finalData = {
      page: page,
      sorted_by: sortBy,
      asc_desc: order,
      ...(name ? { name: name } : {}),
      ...(showAll ? { show_active_orders: false } : {})
    }
    return axios.post(finalUrl, finalData, { withCredentials: true })
  }
  const [orders, setOrders] = useState("");
  const [isNext, setIsNext] = useState(false);
  const searchOrderMutation = useMutation({
    mutationKey: ["searchOrder"],
    mutationFn: searchOrder,
    onSuccess: (data) => {
      // console.log(data.data.length);
      if (data.data.length == 10) {
        const ordersWithoutLastItem = data.data.slice(0, -1);
        setOrders(ordersWithoutLastItem);
        setIsNext(false)
      } else {
        setOrders(data?.data)
        setIsNext(true)
      }
    },
    onError: (error) => {
      console.log(error);
      setOrders("error")
    },

  })
  useEffect(() => {
    searchOrderMutation.mutate();
  }, [])
  useEffect(() => {
    searchOrderMutation.mutate();
  }, [page, order, sortBy, showAll])
  const handleNext = () => {
    if (orders.length !== 0) {

      // setPage((prev) => prev += 1)
      setNextPage()
    }
  }
  const handlePrev = () => {
    if (page > 0) {
      // setPage((prev) => prev -= 1)
      setPrevPage()
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Siparişler" breadcrumbItem="Aktif Siparişler" />
          <Row>

            <Card>
              <CardBody>
                <Row>
                  <Col className="col-6 mb-2 ">
                    <Button onClick={() => setOrder("ASC")} style={{ borderRadius: "8px 0px 0px 8px" }} variant={`${order === "ASC" ? "primary" : "light"}`} ><PiSortAscending size={24} /></Button>
                    <Button onClick={() => setOrder("DESC")} style={{ borderRadius: "0px 8px 8px 0px" }} variant={`${order === "DESC" ? "primary" : "light"}`} ><PiSortDescending size={24} /></Button>
                    <span className=" ms-3 me-2 ">Sıralama:</span>
                    <Select
                      defaultValue="total_amount"
                      style={{
                        width: 150,
                      }}
                      size="middle"
                      onChange={handleChange}
                      options={[
                        {
                          value: 'total_amount',
                          label: 'Toplam tutar',
                        },
                        {
                          value: 'invoice_status',
                          label: 'Fatura Durumu',
                        },
                        {
                          value: 'order_date',
                          label: 'Sipariş tarihi',
                        }
                      ]}
                    />
                  </Col>
                  <Col className="col-6 mb-2 text-end d-flex align-items-center justify-content-end ">
                    <Search
                      placeholder="Arama metnini girin"
                      allowClear
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onSearch={onSearch}
                      style={{
                        width: 200,
                      }}
                    />
                    <span className=" ms-3 me-2 ">Hepsini Göster:</span>
                          
                    <Switch checked={showAll} className="ms-2 me-2" defaultChecked onChange={(checked) => setShowAll(checked)} />
                    <Tooltip
                title="Aktif olmaması durumunda tamamlanmış siparişleriniz gözükmeyecektir."
                overlayClassName="custom-tooltip"
                
              >
                <RiErrorWarningFill style={{ fontSize: "20px" }} />
              </Tooltip>
                  </Col>
                </Row>
                <Col className="col-12">
                  <Table hover >
                    <thead className="">
                      <tr className="">
                        <th>Sipariş Numarası</th>
                        <th>Faturalandırma Adı</th>
                        <th>Tarih</th>
                        <th>Fatura Durumu</th>
                        <th>Toplam Tutar</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        orders?.length === 0 ? (
                          <tr style={{ backgroundColor: "#fff !important" }} className="">
                            <td colSpan={6}>Sipariş yok</td>

                          </tr>
                        ) : orders === "error" ? (
                          <tr style={{ backgroundColor: "#fff !important" }} className="">
                            <td colSpan={6}>Hata</td>

                          </tr>
                        ) : (
                          orders.map((orderItem) => {
                            const invoiceStatus = orderItem.is_invoice_uploaded == 0 ? "yüklenmedi" : "yüklendi"
                            return (
                              <tr key={orderItem.order_id} style={{ backgroundColor: "#fff !important" }} className="">
                                <td >{orderItem.order_id}</td>
                                <td >{orderItem.billing_name}</td>
                                <td >{orderItem.order_date}</td>
                                <td className={`${invoiceStatus == "yüklenmedi" && "text-danger"}`} >{invoiceStatus}</td>
                                <td >{orderItem.total}</td>
                                <td >
                                  <Link to={`/ecommerce-orders-detail?order_id=${orderItem.order_id}`}>
                                    <Button>Detaylar</Button>
                                  </Link>
                                </td>
                              </tr>
                            )
                          })
                        )
                      }

                    </tbody>
                  </Table>
                </Col>

                <Col className=" text-end " xs="12">
                  <Button onClick={handlePrev} style={{ borderRadius: "8px 0px 0px 8px" }} variant="light" >Önceki</Button>
                  <Button disabled={isNext} onClick={handleNext} style={{ borderRadius: "0px 8px 8px 0px" }} variant="light" >Sonraki</Button>
                </Col>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </React.Fragment >
  )
}

export default EcommerceOrders

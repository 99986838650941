import React from "react"
import { Col, Row } from "reactstrap"
import ProductTable from "./ProductTable"

const ProductVariations = ({ sizeAndVariations }) => {
  return (
    <>
      <h5
        className="my-3"
        style={{ fontWeight: 500, fontSize: "19.5px", lineHeight: "29.25px" }}
      >
        Beden ve Renkler
      </h5>
      {sizeAndVariations &&
        sizeAndVariations.length > 0 &&
        sizeAndVariations.map((variation, index) => (
          <Row key={index}>
            <Col xl="5">
              <img
                src={
                  variation.variation_media &&
                  JSON.parse(variation.variation_media)[0]
                }
                alt="Product"
                style={{
                  width: "100%",
                  width: "150px",
                  height: "180px",
                  borderRadius: "6px",
                  objectFit: "cover",
                }}
              />
            </Col>
            <Col xl="7">
              <Row className="mb-2 mt-2">
                <Col xs="auto">
                  <div
                    style={{
                      width: "20px", // Adjust the size of the circle
                      height: "20px", // Adjust the size of the circle
                      backgroundColor:
                        variation.colors.length > 0
                          ? JSON.parse(variation.colors)[0].color_hex
                          : "", // Extract color hex of the first color
                      borderRadius: "50%", // Make it a circle
                      opacity: 0.7, // Adjust the opacity as needed
                      pointerEvents: "none", // Make the circle not block interactions with the image
                      border: "1px solid black",
                      
                    }}
                  ></div>
                </Col>
                <Col>
                  {variation ? (
                    <span>
                    {variation.colors.length > 0
                      ? JSON.parse(variation.colors)[0].color_name.charAt(0).toUpperCase() + JSON.parse(variation.colors)[0].color_name.slice(1)
                      : ""}
                  </span>
                  
                  ) : (
                    <span></span>
                  )}
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: "-8px",
                  marginRight: "-8px",
                }}
              >
                {" "}
                {/* Make the variation_media items wrap and remove the default margins */}
                {variation.variation_media &&
                  JSON.parse(variation.variation_media).map(
                    (variation_media, index) => (
                      <Col
                        className="p-1"
                        xs="auto"
                        lg="auto"
                        key={index}
                        style={{ flex: "0 0 auto", margin: "8px" }}
                      >
                        {" "}
                        {/* Set flex properties to auto and adjust margin */}
                        <img
                          style={{
                            width: "90px",
                            height: "100px",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                          src={variation_media}
                        />
                      </Col>
                    )
                  )}
              </Row>
            </Col>
            <Col xl="12">
              <ProductTable
                products={variation.sizes && JSON.parse(variation.sizes)}
              />
            </Col>
          </Row>
        ))}
    </>
  )
}

export default ProductVariations

import React, { useEffect, useState, useMemo } from "react";
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody
} from "reactstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import Image from 'react-bootstrap/Image';
import "./style.css"
import { toast } from 'react-toastify';

const PromotionsPopup = ({ modalIsOpen, closeModall, id }) => {
    const [productDetailsState, setProductDetailsState] = useState([]);
    const [productNameState, setProductNameState] = useState("");

    const [sizeErrorId, setSizeErrorId] = useState(0);
    const [sizeErrorOption, setSizeErrorOption] = useState("");

    function closeModal() {
        closeModall();
        setSizeErrorId(0)
        setSizeErrorOption("")
    }
    const getProductData = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/get-update-product-data?product_id=${id}`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const { isLoading, isError, error, data, refetch } = useQuery({ queryKey: ["product-data"], queryFn: getProductData })
    let productDetails
    if (isLoading) {
        productDetails = "loading"
    } else if (isError) {
        // console.log(error);
    } else {
        // console.log(data.data);
        productDetails = data.data.product_data
    }
    useMemo(() => {
        setProductDetailsState(data?.data.product_data)
        setProductNameState(data?.data?.product_name)
    }, [data])
    const handleChange = (e, variation_id, size_id, field) => {
        // console.log(e, variation_id, size_id, field);
        setProductDetailsState((prevProductDetails) => {
            return prevProductDetails.map((product) =>
                product.variation_id === variation_id
                    ? {
                        ...product,
                        sizes: product.sizes.map((size) =>
                            size.size_id === size_id ? { ...size, [field]: e.target.value } : size
                        ),
                    }
                    : product
            );
        });

    }
    const updateProduct = (props) => {
        let finalData = {}
        if (props.size_id === -1) {
            finalData = {
                product_id: props.product_id,
                option: props.option,
                new_value: `${props.new_value}`,
            }
        } else {

            finalData = {
                size_id: props.size_id,
                option: props.option,
                new_value: `${props.new_value}`,
            }
        }
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/update-product`
        // console.log(finalData)
        return axios.put(finalUrl, finalData, { withCredentials: true })
    }
    const updateProductMutation = useMutation({
        mutationFn: updateProduct,
        onSuccess: (data) => {
            // console.log(data);
            toast.success("Ürün başarıyla güncellendi", { toastId: "updateProductMutation" })
            setSizeErrorId(0)
            setSizeErrorOption("")
        },
        onError: (error, variables) => {
            // console.log(error);
            if (error.response.data.detail[0].msg === "price_in_butiko must be smaller than price_outside_butiko") {
                toast.error("Butiko içi fiyat Butiko dışı fiat dan büyük olamaz.", { toastId: "updateProductMutationError" });
            } else if (error.response.data.detail[0].msg === "Input should be greater than 0") {
                toast.error("0 olamaz.", { toastId: "priceError" })
            } else if (error.response.data.detail[0].msg === "invalid size_id") {
                toast.error("geçersiz beden.", { toastId: "priceError" })
            } else if(error.response.data.detail[0].msg === "unsupported new_value for stock_quantity"){
                toast.error("desteklenmeyen değer.", { toastId: "priceError" })
            }else if(error.response.data.detail[0].msg === "unsupported new_value for price_in_butiko or price_outside_butiko"){
                toast.error("desteklenmeyen değer.", { toastId: "priceError" })
            } else if(error.response.data.detail[0].msg === "invalid option type"){
                toast.error("geçersiz.", { toastId: "priceError" })
            } else {
                toast.error("Bir hata oluştu.", { toastId: "someError" })
            }
            // console.log(variables.size_id);
            setSizeErrorId(variables.size_id)
            setSizeErrorOption(variables.option)
            refetch()
        }
    })

    return (

        <Modal
            isOpen={modalIsOpen}
            // onRequestClose={closeModal}
            // contentLabel="Example Modal"
            toggle={closeModal}
            style={{
                maxWidth: "80rem",
                // maxHeight: "1100px",
                marginTop: "5rem",
            }}
        >
            <ModalHeader toggle={closeModal}>Ürünü Düzenle </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs="12" lg="12">
                        {Array.isArray(productDetailsState) && productDetailsState.map((product, index) => {
                            return (
                                <Row key={index} className="color-row mb-2">
                                    <Col className="col-3 align-items-center d-flex mb-3 mt-2 ">
                                        <Image width="150px" src={product.variation_media[0]} rounded fluid />
                                        <p className="ms-2">{product.color.color_name}</p>
                                        <div style={{
                                            display: "inline-block",
                                            width: "16px",
                                            height: "16px",
                                            borderRadius: "50%",
                                            backgroundColor: product.color.color_hex,
                                            boxShadow: "2px 2px 3px 0px #cfcfcf",
                                            marginBottom: "16px",
                                            marginLeft: "5px"
                                        }}></div>
                                    </Col>
                                    {index===0 && (<Col  className="col-4 d-flex align-items-center">
                                        <Form style={{ flexGrow: 1 }} onSubmit={(e) => {
                                            e.preventDefault()
                                            if (data?.data?.product_name === productNameState) {
                                                toast.info("Değerlerde Herhangi Bir Değişiklik Yok", { toastId: "productNameError" })
                                            } else {
                                                updateProductMutation.mutate({ product_id: id, size_id: -1, option: "product_name", new_value: productNameState })
                                            }
                                        }}>

                                            <Form.Label>Ürün adı</Form.Label>
                                            <InputGroup  className="mb-3">
                                                <Form.Control
                                                    placeholder="Ürün adı"
                                                    // className={`${sizeErrorId === size.size_id && sizeErrorOption === "price_in_butiko" ? "border-danger " : ("")}`}
                                                    value={productNameState}
                                                    onChange={(e) => setProductNameState(e.target.value)}
                                                    style={{ flexGrow: 1 }}
                                                    type="text"
                                                    required
                                                />
                                                <Button
                                                    variant="secondary"
                                                    type="submit"
                                                    style={{backgroundColor:"#34C38F", border:"1px solid #CED4DA"}}
                                                // className={`${sizeErrorId === size.size_id && sizeErrorOption === "price_in_butiko" ? "border-danger " : ("")}`}
                                                >
                                                    Düzenle
                                                </Button>
                                            </InputGroup>
                                        </Form>
                                    </Col>)}
                                    {product.sizes.map((size, index) => {
                                        return (
                                            <Row key={`size-${index}`}>
                                                <Col className="col-1 align-items-center d-flex justify-content-center ">
                                                    <p>Beden: <strong>{size.size_name}</strong> </p>
                                                </Col>
                                                <Col className="col  ">
                                                    <Form onSubmit={(e) => {
                                                        e.preventDefault()
                                                        updateProductMutation.mutate({ size_id: size.size_id, option: "price_in_butiko", new_value: size.price_in_butiko })
                                                    }}>

                                                        <Form.Label>Butiko fiyatı</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                placeholder="price_in_butiko"
                                                                className={`${sizeErrorId === size.size_id && sizeErrorOption === "price_in_butiko" ? "border-danger " : ("")}`}
                                                                value={size.price_in_butiko}
                                                                onChange={(e) => handleChange(e, product.variation_id, size.size_id, "price_in_butiko")}

                                                                type="number"
                                                                required
                                                            />
                                                            <Button
                                                                variant="secondary"
                                                                type="submit"
                                                                style={{backgroundColor:"#34C38F", border:"1px solid #CED4DA"}}
                                                                className={`${sizeErrorId === size.size_id && sizeErrorOption === "price_in_butiko" ? "border-danger " : ("")}`}
                                                            >
                                                                Düzenle
                                                            </Button>
                                                        </InputGroup>
                                                    </Form>
                                                </Col>
                                                <Col className="col">
                                                    <Form onSubmit={(e) => {
                                                        e.preventDefault()
                                                        updateProductMutation.mutate({ size_id: size.size_id, option: "price_outside_butiko", new_value: size.price_outside_butiko })
                                                    }}>
                                                        <Form.Label>Butiko dışındaki fiyat</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                placeholder="price_outside_butiko"

                                                                value={size.price_outside_butiko}
                                                                required
                                                                className={`${sizeErrorId === size.size_id && sizeErrorOption === "price_outside_butiko" ? "border-danger " : ("")}`}
                                                                onChange={(e) => handleChange(e, product.variation_id, size.size_id, "price_outside_butiko")}
                                                            />
                                                            <Button
                                                                className={`${sizeErrorId === size.size_id && sizeErrorOption === "price_outside_butiko" ? "border-danger " : ("")}`}
                                                                type="submit"
                                                                variant="secondary"
                                                                style={{backgroundColor:"#34C38F", border:"1px solid #CED4DA"}} >
                                                                Düzenle
                                                            </Button>
                                                        </InputGroup>
                                                    </Form>
                                                </Col>
                                                <Col className="col">
                                                    <Form onSubmit={(e) => {
                                                        e.preventDefault()
                                                        updateProductMutation.mutate({ size_id: size.size_id, option: "stock_quantity", new_value: size.stock_quantity })
                                                    }}>
                                                        <Form.Label>Stok miktarı</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                placeholder="stock_quantity"
                                                                className={`${sizeErrorId === size.size_id && sizeErrorOption === "stock_quantity" ? "border-danger " : ("")}`}
                                                                value={size.stock_quantity}
                                                                required
                                                                onChange={(e) => handleChange(e, product.variation_id, size.size_id, "stock_quantity")}

                                                            />
                                                            <Button
                                                                type="submit"
                                                                className={`${sizeErrorId === size.size_id && sizeErrorOption === "stock_quantity" ? "border-danger " : ("")}`}
                                                                variant="secondary" 
                                                                style={{backgroundColor:"#34C38F", border:"1px solid #CED4DA"}}>
                                                                Düzenle
                                                            </Button>
                                                        </InputGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        )
                                    })}


                                </Row>)
                        })
                        }


                    </Col>

                </Row>
            </ModalBody>
            <ModalFooter>

                <Button color="secondary" onClick={closeModal} className="">
                    Kapat
                </Button>

            </ModalFooter>
        </Modal>
    );
}

export default PromotionsPopup;
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Notifications } from "react-push-notification"
import { generateToken, messaging } from "pages/Notification/firebase"
import { onMessage } from "firebase/messaging"
import { trackPageView } from "./analytics"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))
  const queryClient = new QueryClient()

  const Layout = getLayout(layoutType)
  const location = useLocation()

  // useEffect(() => {
  //   generateToken()
  //   onMessage(messaging, (payload => {
  //     console.log(payload);
  //   }))
  // }, []);

  useEffect(() => {
    trackPageView(location.pathname + location.search)
  }, [location])

  // !meta
  useEffect(() => {
    if (window.location.hostname === "butikoseller.com") {
      const script = document.getElementById("meta")
      const scriptContent = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '338051092697935');
      fbq('track', 'PageView');`
      script.innerHTML = scriptContent

      const noscript = document.getElementById("nometa")
      const noScriptContent = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=338051092697935&ev=PageView&noscript=1"
      />`
      noscript.innerHTML = noScriptContent

      const clarity = document.getElementById("clarity")
      const clarityContent = `
     (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "n5rbr3q4tz");`
      clarity.innerHTML = clarityContent
    }
  }, [])

  return (
    <React.Fragment>
      <Notifications />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Routes>
      </QueryClientProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App

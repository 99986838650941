import React, { useState, useEffect } from "react"
import axios from "axios"
import { Table, Button, CardBody, Card } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const Contracts = () => {
  const [contracts, setContracts] = useState([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchContracts = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/contracts/get-contracts?page=${page}`,
          { withCredentials: true }
        )
        const sortedContracts = response.data.sort(
          (a, b) => new Date(b.publish_date) - new Date(a.publish_date)
        )
        setContracts(sortedContracts)
        console.log(sortedContracts)
      } catch (error) {
        console.error("Veriler getirilemedi:", error)
      } finally {
        setLoading(false)
      }
    }
    fetchContracts()
  }, [page])

  const markAsInformed = async contractId => {
    const contract = contracts.find(c => c.contract_id === contractId)

    if (contract && contract.informed_at === null) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/contracts/mark-contract-as-informed`,
          { contract_id: contractId },
          { withCredentials: true }
        )
        console.log(`Sözleşme ${contractId} bilgilendirildi.`)

        const updatedContracts = contracts.map(c => {
          if (c.contract_id === contractId) {
            return { ...c, informed_at: new Date().toISOString() }
          }
          return c
        })
        setContracts(updatedContracts)
      } catch (error) {
        console.error("API isteği sırasında hata oluştu:", error)
      }
    }
  }

  const viewContract = (contractId, contractLink) => {
    const contract = contracts.find(c => c.contract_id === contractId)
    if (contract) {
      markAsInformed(contractId)
      window.open(contractLink, "_blank")
    }
  }

  const nextPage = () => setPage(prevPage => prevPage + 1)
  const prevPage = () => setPage(prevPage => (prevPage > 0 ? prevPage - 1 : 0))

  const tdStyle = {
    fontSize: "15px",
    marginBottom: "-5px",
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Hesabım" breadcrumbItem="Sözleşmelerim" />
        {loading ? (
          <p>Yükleniyor...</p>
        ) : (
          <Card>
            <CardBody style={{ width: "100%" }}>
              <Table bordered className="text-center" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Sözleşme Adı</th>
                    <th>Yayımlanma Tarihi</th>
                    <th>Bilgilendirilme Tarihi</th>
                    <th>Sözleşme</th>
                  </tr>
                </thead>
                <tbody>
                  {contracts.map(contract => (
                    <tr key={contract.contract_id}>
                      <td style={tdStyle}>{contract.contract_name}</td>
                      <td style={tdStyle}>
                        {new Date(contract.publish_date).toLocaleString(
                          "tr-TR",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                        {" - "}
                        {new Date(contract.publish_date).toLocaleString(
                          "tr-TR",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          }
                        )}
                      </td>
                      <td style={tdStyle}>
                        {contract.informed_at &&
                        new Date(contract.informed_at).getTime() ? (
                          <>
                            {new Date(contract.informed_at).toLocaleString(
                              "tr-TR",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                            {" - "}
                            {new Date(contract.informed_at).toLocaleString(
                              "tr-TR",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              }
                            )}
                          </>
                        ) : (
                          <span
                            style={{
                              color: "white",
                              backgroundColor: "rgb(231 31 43)",
                              padding: "6px 10px",
                              borderRadius: "8px",
                              fontWeight: "400",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Bilgilendirilmedi
                            <ExclamationCircleOutlined
                              style={{ marginLeft: "5px", color: "white" }}
                            />
                          </span>
                        )}
                      </td>

                      <td>
                        <button
                          onClick={() =>
                            viewContract(
                              contract.contract_id,
                              contract.contract_link
                            )
                          }
                          style={{
                            padding: "5px 10px",
                            borderRadius: "5px",
                            borderColor: "gray",
                            backgroundColor: "white",
                          }}
                        >
                          Görüntüle
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        )}
        <div
          className="d-flex justify-content-end"
          style={{ width: "100%", marginTop: "20px" }}
        >
          <Button
            color="secondary"
            onClick={prevPage}
            disabled={page === 0}
            style={{ marginRight: "10px", width: "5rem" }}
          >
            Geri
          </Button>
          <Button
            color="primary"
            onClick={nextPage}
            disabled={contracts.length < 10}
            style={{ width: "6rem" }}
          >
            İleri
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Contracts

import React from "react"
import { Navigate } from "react-router-dom"
import Chat from "../pages/Chat/Chat"
import Question from "../pages/Chat/Questions"
import Tokens from "../pages/CampaignsAndTokens/Tokens"
import Ads from "../pages/CampaignsAndTokens/Ads"
import AdsProductDetail from "../pages/CampaignsAndTokens/AdsDetail"
import InvoicesList from "../pages/Invoices/invoices-list"
import UserProfile from "pages/Authentication/user-profile"
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/Products"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/ProductDetail"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceOrderDetails from "../pages/Ecommerce/EcommerceOrders/EcommerceOrderDetail"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/Register/Register"
import Register2 from "../pages/Register/Register2"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"
import Dashboard from "../pages/Dashboard/index"
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"
import Notifications from "../pages/Notification/Notifications"
import ReviewDetails from "../pages/Review/ReviewDetails"
import Collections from "../pages/Collections/collection"
import PersonProfile from "pages/Authentication/person-profile"
import Sorular from "pages/Faq/faq"
import Contracts from "pages/Contracts/index"
import EditCollections from "pages/Collections/components/EditCollection"
import AboutStore from "../pages/AboutStore/AboutStore"
import AboutStoreEdit from "../pages/AboutStore/AboutStoreEdit"
import Reviews from "pages/Review/Reviews"
import AddCollection from "pages/Collections/components/AddCollection"
import ReviewsDetail from "pages/Ecommerce/EcommerceCustomers/ReviewDetail"
import ChangePassword from "pages/AuthenticationInner/ChangePassword"
import NotFound from "pages/404/NotFound"
import Transactions from "pages/Transactions/Transactions"
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/ecommerce-orders-detail", component: <EcommerceOrderDetails /> },
  { path: "/sorular", component: <Sorular /> },
  {
    path: "/ecommerce-product-detail/:id",
    component: <EcommerceProductDetail />,
  },
  { path: "/add-collections", component: <AddCollection /> },
  { path: "/edit-collections/:collectionId", component: <EditCollections /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/review-details/:review_id", component: <ReviewDetails /> },
  { path: "/person-profile", component: <PersonProfile /> },
  { path: "/contracts", component: <Contracts /> },

  { path: "/about-store", component: <AboutStore /> },
  { path: "/about-store-edit", component: <AboutStoreEdit /> },
  { path: "/collections", component: <Collections /> },
  { path: "/chat", component: <Chat /> },
  { path: "/questions", component: <Question /> },
  { path: "/reviews", component: <Reviews /> },
  { path: "/reviews-detail/:review_id", component: <ReviewsDetail /> },
  { path: "/tokens", component: <Tokens /> },
  { path: "/ads", component: <Ads /> },
  { path: "/ads-product-detail/:ad_id", component: <AdsProductDetail /> },
  { path: "/ecommerce-products", component: <EcommerceProducts /> },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/reviews", component: <Reviews /> },
  { path: "/ecommerce-cart", component: <EcommerceCart /> },
  { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/transactions", component: <Transactions /> },
  { path: "/profile", component: <UserProfile /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "/*",
    component: <NotFound />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login2 /> },
  { path: "/register", component: <Register1 /> },
  { path: "/register-2", component: <Register2 /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  {
    path: "/auth-two-step-verification-2",
    component: <TwostepVerification2 />,
  },
  { path: "/change-password", component: <ChangePassword /> },
]

export { authProtectedRoutes, publicRoutes }

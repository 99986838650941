import React, { useState, useEffect } from "react"
import { useQuery, useMutation } from "@tanstack/react-query"
import withRouter from "components/Common/withRouter"
import {
  Button,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap"
import ColorCircle from "../ColorCircle"
import axios from "axios"
import Timeline from './Timeline';
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensor, useSensors } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy, arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable"
import Calendar from "../Calender.js"
import { addDays } from "@fullcalendar/react"
// import toastr from "toastr"
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom"
import AdSelectFilters from "../Components/AdSelectFilters"
import { SortableItem } from "./SortableItem"
import { useTokenCount } from "components/CommonForBoth/TopbarDropdown/TokenCount"
// import "../products.css"
import product1 from "../../../assets/images/logo/logo-white.png"
const NewAddPopup = ({ filterMutation, modalOpen, setModalOpen }) => {
  const history = useNavigate()

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  //Tabloda Product Name aramak
  const [tableFilter, setTableFilter] = useState("")

  const [districtId, setDistrictId] = useState(0)

  const [semtId, setSemtId] = useState(0)

  const [selectedRank, setSelectedRank] = useState("")

  const [tokenCost, setTokenCost] = useState(0)

  const [startDate, setStartDate] = useState(null) //useState(addDays(new Date(), 1))
  const [endDate, setEndDate] = useState(null)


  const [balanceError, setBalanceError] = useState(false)
  const { tokenCount, tokenBalanceRefetch } = useTokenCount()



  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  const handleDistrictChange = e => {
    setDistrictId(e.target.value)
  }

  const handleSemtChange = e => {
    setSemtId(e.target.value)
  }

  const handleRankChange = e => {
    const newRank = e.target.value
    setSelectedRank(newRank)
  }


  const getDistrict = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/ilce/40`, {
      withCredentials: true,
    })
  }
  const getSemt = () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/semt/${districtId}`,
      { withCredentials: true }
    )
  }
  // const getToken = () => {
  //   return axios.get(
  //     "${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/ads/get-token-balance",
  //     { withCredentials: true }
  //   )
  // }



  // const addPromotion = () => {
  //   if (
  //     orderedProducts.length > 0 &&
  //     selectedRank !== 0 &&
  //     semtId !== 0 &&
  //     startDate &&
  //     endDate &&
  //     tokenCost !== 0
  //   ) {
  //     const finalUrl =
  //       "${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/ads/add-promotion"
  //     const startYear = startDate.getFullYear()
  //     const startMonth = String(startDate.getMonth() + 1).padStart(2, "0")
  //     const startDay = String(startDate.getDate()).padStart(2, "0")
  //     const endYear = endDate.getFullYear()
  //     const endMonth = String(endDate.getMonth() + 1).padStart(2, "0")
  //     const endDay = String(endDate.getDate()).padStart(2, "0")

  //     const startFormattedDate = `${startYear}-${startMonth}-${startDay}`
  //     const endFormattedDate = `${endYear}-${endMonth}-${endDay}`
  //     const finalProducts = orderedProducts.map((item, index) => ({
  //       product_id: item.product_id,
  //       order: index + 1,
  //     }))

  //     const finalData = {
  //       ranking: selectedRank,
  //       semt_id: semtId,
  //       token_cost: tokenCost,
  //       start_date: startFormattedDate,
  //       end_date: endFormattedDate,
  //       products: finalProducts,
  //     }
  //     return axios.post(finalUrl, finalData, { withCredentials: true })
  //   } else {
  //     toast.warning("Lütfen ürün seçin", { toastId: "selectProductWarning" })
  //     throw new Error("Value must be a non-negative number")

  //   }
  // }
  const addPromotion = () => {
    // Check if products are selected
    if (orderedProducts.length === 0) {
      toast.warning("Lütfen en az bir ürün seçin", { toastId: "noProductsSelected" });
      throw new Error("No products selected");
    }
    // Check if semt is selected
    if (semtId === 0) {
      toast.warning("Lütfen bir semt seçin", { toastId: "noSemtSelected" });
      throw new Error("No semt selected");
    }

    // Check if rank is selected
    if (!selectedRank) {
      toast.warning("Lütfen bir sıralama seçin", { toastId: "noRankSelected" });
      throw new Error("No rank selected");
    }


    // Check if start date is set
    if (!startDate) {
      toast.warning("Lütfen bir başlangıç tarihi seçin", { toastId: "noStartDateSelected" });
      throw new Error("No start date selected");
    }

    // Check if end date is set
    if (!endDate) {
      toast.warning("Lütfen bir bitiş tarihi seçin", { toastId: "noEndDateSelected" });
      throw new Error("No end date selected");
    }

    // Check if token cost is set
    if (tokenCost === 0) {
      // toast.warning("Lütfen bir token maliyeti girin", { toastId: "noTokenCostEntered" });
      toast.warning("Hata", { toastId: "noTokenCostEntered" });
      throw new Error("No token cost entered");
    }

    // If all checks pass, proceed with the promotion creation
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/promotions/create-promotion`;
    const startYear = startDate.getFullYear();
    const startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
    const startDay = String(startDate.getDate()).padStart(2, "0");
    const endYear = endDate.getFullYear();
    const endMonth = String(endDate.getMonth() + 1).padStart(2, "0");
    const endDay = String(endDate.getDate()).padStart(2, "0");

    const startFormattedDate = `${startYear}-${startMonth}-${startDay}`;
    const endFormattedDate = `${endYear}-${endMonth}-${endDay}`;
    // const finalProducts = orderedProducts.map((item, index) => ({
    //   product_id: item.product_id,
    //   order: index + 1,
    // }));
    const finalProducts = orderedProducts.map(item => item.product_id);


    const finalData = {
      ranking: selectedRank,
      semt_id: semtId,
      token_cost: tokenCost,
      start_date: startFormattedDate,
      end_date: endFormattedDate,
      product_ids: finalProducts,
    };

    return axios.post(finalUrl, finalData, { withCredentials: true });
  };

  const [selectedProducts, setSelectedProducts] = useState([])
  const handleToggle = productId => {
    if (orderedProducts.length === 3 && !orderedProducts.some(obj => obj.product_id === productId)) {
      toast.warning("En Fazla 3 Ürün", { toastId: "max-3-products" })
    } else {

      if (orderedProducts.some(obj => obj.product_id === productId)) {
        const newArray = orderedProducts.filter(
          obj => obj.product_id !== productId
        )
        setOrderedProducts(newArray)
      } else {
        const targetProduct = productData.data.filter(
          product => product.product_id === productId
        )
        setOrderedProducts(prev => [...prev, targetProduct[0]])
      }
    }
  }

  const getProducts = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/promotions/search-product?search=${tableFilter}`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const {
    isLoading,
    isError,
    data: productData,
    error,
    isSuccess,
  } = useQuery({
    queryKey: ["productData", tableFilter],
    queryFn: getProducts,
  })
  if (isLoading) {
  }
  if (isError) {
    if (
      error?.response?.data?.detail[0]?.msg === "unathorized action" ||
      error?.response?.data?.detail[0]?.msg === "invalid jwt"
    ) {
      history("/login")
    }
    toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "someError" });
  }
  if (isSuccess) {
  }
  const {
    isLoading: districtIsLoading,
    isError: districtIsError,
    error: districtError,
    data: districtData,
  } = useQuery({ queryKey: ["district"], queryFn: getDistrict })
  let districtOptions
  if (districtIsLoading) {
    districtOptions = <option value="">Yükleniyor..</option>
  } else if (districtIsError) {
    if (
      districtError?.response?.data?.detail[0]?.msg === "unathorized action" ||
      districtError?.response?.data?.detail[0]?.msg === "invalid jwt"
    ) {
      history("/login")
    }
    districtOptions = <option value="">HATA Oluştu!</option>
  } else {
    districtOptions = districtData.data.map(data => (
      <option key={data.ilce_id} value={data.ilce_id}>
        {data.ilce}
      </option>
    ))
  }
  const {
    isLoading: semtIsLoading,
    isError: semtIsError,
    error: semtError,
    data: semtData,
  } = useQuery({
    queryKey: ["semt", districtId],
    queryFn: getSemt,
    enabled: Boolean(districtId),
  })
  let semtOptions
  if (semtIsLoading) {
    districtOptions = <option value="">Yükleniyor..</option>
  } else if (semtIsError) {
    if (
      semtError.response.data.detail[0].msg === "unathorized action" ||
      semtError.response.data.detail[0].msg === "invalid jwt"
    ) {
      history("/login")
    }
    districtOptions = <option value="">HATA Oluştu!</option>
  } else {
    semtOptions = semtData?.data.map(data => (
      <option key={data.semt_id} value={data.semt_id}>
        {data.semt}
      </option>
    ))
  }

  const [orderedProducts, setOrderedProducts] = useState([])
  const [rankedList, setRankedList] = useState(orderedProducts)
  useEffect(() => {
    setRankedList(orderedProducts)
  }, [orderedProducts])


  const getProductPos = id => orderedProducts.findIndex(product => product.product_id === id)
  const handleDndKitDragEnd = (event) => {
    const { active, over } = event
    if (active?.id === over?.id) return;
    setOrderedProducts(product => {
      const originalPos = getProductPos(active.id);
      const newPos = getProductPos(over.id);
      return arrayMove(product, originalPos, newPos)
    })
  }
  const getCost = () => {
    const finalUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/promotions/calculate-costs`
    const startYear = startDate.getFullYear()
    const startMonth = String(startDate.getMonth() + 1).padStart(2, "0")
    const startDay = String(startDate.getDate()).padStart(2, "0")
    const endYear = endDate.getFullYear()
    const endMonth = String(endDate.getMonth() + 1).padStart(2, "0")
    const endDay = String(endDate.getDate()).padStart(2, "0")

    const startFormattedDate = `${startYear}-${startMonth}-${startDay}`
    const endFormattedDate = `${endYear}-${endMonth}-${endDay}`
    const finalData = {
      semt_id: semtId,
      ranking: selectedRank,
      start_date: startFormattedDate,
      end_date: endFormattedDate,
    }
    if (startDate && endDate && semtId !== 0 && selectedRank !== 0) {
      return axios.post(finalUrl, finalData, { withCredentials: true })
    } else {
      return
    }
  }
  const calculateCostMutation = useMutation({
    mutationFn: getCost,
    onSuccess: data => {
      setTokenCost(data.data.token)
    },
    onError: error => {
      if (
        error.response.data.detail[0].msg === "unathorized action" ||
        error.response.data.detail[0].msg === "invalid jwt"
      ) {
        history("/login")
      }
    },
  })
  const addPromotionMutation = useMutation({
    mutationFn: addPromotion,
    onSuccess: e => {
      // console.log("okkkkkk");
      setBalanceError(false)
      setSelectedRank(0)
      setSemtId(0)
      setStartDate(addDays(new Date(), 1))
      setStartDate(null)
      setEndDate(null)
      setRankedList([])
      setSelectedProducts([])
      setTokenCost(0)
      // queryClient.invalidateQueries({ refetchType: "inactive" })
      setOrderedProducts([])
      filterMutation.mutate()
      toggleModal()
      tokenBalanceRefetch()
      toast.success("Reklam başarıyla alındı", { toastId: "promotionSuccess" })
    },
    onError: error => {
      console.log(error)
      if (
        error.response.data.detail[0].msg === "unathorized action" ||
        error.response.data.detail[0].msg === "invalid jwt"
      ) {
        history("/login")
      }
      else if (
        error.response.data.detail[0].msg ===
        "cool down exist for this semt_id and ranking"
      ) {
        toast.error("Bu semt ve sıralama için kısıtlamanız mevcuttur.", { toastId: "cooldownError" })
      } else if (
        error.response.data.detail[0].msg === "promotion duplication"
      ) {
        toast.error("Promosyon çakışması")
      } else if (
        error?.response?.data?.detail[0]?.msg === "insufficient balance"
      ) {
        setBalanceError(true)
      } else if (error?.response?.data?.detail[0]?.msg === "at least one invalid product_id in products array") {
        toast.error("Geçersiz Ürün")
      } else if (error?.response?.data?.detail[0]?.msg === "invalid semt_id") {
        toast.error("Geçersiz Semt")
      } else if (error?.response?.data?.detail[0]?.msg.includes("List should have at most 3 items after validation")) {
        toast.error("En Fazla 3 Ürün")
      } else {
        toast.error("hata")
      }
    },
  })
  useEffect(() => {
    calculateCostMutation.mutate()
  }, [semtId, selectedRank, startDate, endDate])

  return (
    <>
      <Container fluid={true}>
        <Modal
          style={{
            maxWidth: "90vw",
            maxHeight: "1200px",
            marginTop: "3rem",
          }}
          isOpen={modalOpen}
          toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}>Ürününüzü Seçin </ModalHeader>
          <ModalBody className="mx-3 my-3">
            <Row>
              <Timeline />
            </Row>
            <Row>
              <Col className="p-0" lg={4}>
                <Row>
                  <Col className="d-flex flex-column justify-content-center">
                    <i className="bx bx-search-alt position-absolute fs-4 ms-1" />
                    <input
                      onChange={e => {
                        setTableFilter(e.target.value)
                      }}
                      className="form-control rounded-pill"
                      placeholder="Bir ürün adı aratın..."
                      style={{
                        width: "100%",
                        backgroundColor: "#f3f3f9",
                        paddingLeft: "40px",
                      }}
                    />
                  </Col>
                </Row>

                <div
                  style={{ maxHeight: "500px", overflow: "auto" }}
                  className="table-responsive mt-3"
                >
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Ürünler</th>
                        <th>Ürün Açıklaması</th>
                      </tr>
                    </thead>
                    <tbody className="my-3">
                      {productData?.data?.map((product, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: orderedProducts.some(
                              obj => obj?.product_id == product?.product_id
                            ) ? "red" : "transparent",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            console.log(product.product_id);
                            handleToggle(product.product_id)
                          }}
                          className=""
                        >
                          <td>

                            <input
                              type="checkbox"
                              onChange={() => { }}
                              checked={orderedProducts.some(
                                obj => obj?.product_id == product?.product_id
                              ) ? true : false}
                            // onChange={() => handleCheckboxChange(product.product_id)}
                            />
                          </td>
                          <td className="text-nowrap ">
                            {product && product?.variation_detail ? (
                              <img
                                src={
                                  product.variation_detail[0]
                                    .variation_media[0]
                                }
                                style={{
                                  width: "80px",
                                  height: "100px",
                                  objectFit: "cover",
                                  border: "1px solid  #eee",
                                  borderRadius: "8px"
                                }}
                                className="img-fluid mx-auto d-block"
                              />
                            ) : (
                              <img
                                src={product1}
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  borderRadius: "8px",
                                  objectFit: "cover"
                                }}
                                className="img-fluid mx-auto d-block"
                              />
                            )}
                          </td>
                          <td  >
                            {product.name && (
                              <p className="mt-2" style={{ fontWeight: 500, marginLeft: "1rem" }}>
                                {product.name}

                              </p>
                            )}
                            {product.product_id && (
                              <p style={{ fontWeight: 400, marginLeft: "1rem" }}>
                                Code: {product.product_id}
                              </p>
                            )}
                            {product.variation_detail && (
                              product.variation_detail.length > 3 ? (
                                // Render something else if the length is more than 3
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  {product.variation_detail.slice(0, 3).map((color, index) => (
                                    <ColorCircle
                                      key={index}
                                      color={color.color.color_hex}
                                    />
                                  ))}
                                  <div
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: "6px",
                                      marginBottom: "8px",

                                    }}
                                  >
                                    +{product.variation_detail.length - 3}
                                  </div>
                                </div>
                              ) : (
                                // Render ColorCircle components if the length is not more than 3
                                product.variation_detail.map((color, index) => (
                                  <ColorCircle
                                    key={index}
                                    color={color.color.color_hex}
                                  />
                                ))
                              )
                            )}

                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col className="p-0" lg={4}>
                <div className="d-flex justify-content-start ">
                  <div className="position-relative col-12 col-sm-7 col-md-5"></div>
                </div>
                <div
                  style={{ maxHeight: "500px", overflow: "auto", margin: "2rem" }}
                  className="table-responsive mt-3"
                >
                  <table>
                    <thead>
                      <tr>
                        {/* <th className="px-3 py-3">Sıra</th> */}
                        <th className="px-3 py-3">Ürünler</th>
                        <th className="px-3 py-3">Ürün Açıklaması</th>
                        {/* <th className="px-3 py-3">Renkler</th> */}
                      </tr>
                    </thead>
                    <tbody className="m-3">
                      {orderedProducts.length !== 0 ? (
                        // <DndContext sensors={sensors} onDragEnd={handleDndKitDragEnd} collisionDetection={closestCorners}>
                        //   <SortableContext items={orderedProducts.map(product => product.product_id)} strategy={verticalListSortingStrategy}>

                        //     {orderedProducts.map((product, index) => (
                        //       <SortableItem product={product} index={index} id={product.product_id} key={product.product_id} />


                        //     ))}
                        //   </SortableContext>
                        // </DndContext>
                        orderedProducts.map((product, index) => {
                          return (
                            <>
                              <tr
                                key={index}
                                style={{
                                  backgroundColor: orderedProducts.some(
                                    obj => obj?.product_id == product?.product_id
                                  ) ? "red" : "transparent",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  console.log(product.product_id);
                                  handleToggle(product.product_id)
                                }}
                                className=""
                              >

                                <td className="text-nowrap ">
                                  {product && product?.variation_detail ? (
                                    <img
                                      src={
                                        product.variation_detail[0]
                                          .variation_media[0]
                                      }
                                      style={{
                                        width: "80px",
                                        height: "100px",
                                        objectFit: "cover",
                                        border: "1px solid  #eee",
                                        borderRadius: "8px"
                                      }}
                                      className="img-fluid mx-auto d-block"
                                    />
                                  ) : (
                                    <img
                                      src={product1}
                                      style={{
                                        width: "70px",
                                        height: "70px",
                                        borderRadius: "8px",
                                        objectFit: "cover"
                                      }}
                                      className="img-fluid mx-auto d-block"
                                    />
                                  )}
                                </td>
                                <td  >
                                  {product.name && (
                                    <p className="mt-2" style={{ fontWeight: 500, marginLeft: "1rem" }}>
                                      {product.name}

                                    </p>
                                  )}
                                  {product.product_id && (
                                    <p style={{ fontWeight: 400, marginLeft: "1rem" }}>
                                      Code: {product.product_id}
                                    </p>
                                  )}
                                  {product.variation_detail && (
                                    product.variation_detail.length > 3 ? (
                                      // Render something else if the length is more than 3
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        {product.variation_detail.slice(0, 3).map((color, index) => (
                                          <ColorCircle
                                            key={index}
                                            color={color.color.color_hex}
                                          />
                                        ))}
                                        <div
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "50%",
                                            display: "inline-flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            margin: "6px",
                                            marginBottom: "8px",

                                          }}
                                        >
                                          +{product.variation_detail.length - 3}
                                        </div>
                                      </div>
                                    ) : (
                                      // Render ColorCircle components if the length is not more than 3
                                      product.variation_detail.map((color, index) => (
                                        <ColorCircle
                                          key={index}
                                          color={color.color.color_hex}
                                        />
                                      ))
                                    )
                                  )}

                                </td>

                              </tr>
                            </>)
                        })
                      ) : (
                        <tr style={{ backgroundColor: "#FFF9EF" }} className="p-3">
                          <td style={{ backgroundColor: "#FFF9EF" }} className="p-3 fw-bold " colSpan={6}>Lütfen ürün seçimi kısmından ürün seçiniz.<br /> Seçilen ürünler kullanıcılara belirtilen sıraya göre gösterilecektir.</td>
                        </tr>
                      )}
                    </tbody>

                  </table>
                </div>
              </Col>
              {/* -----------------------------Cost part----------------------------- */}
              <Col lg={4}>
                <Row className="mt-2">
                  <Col xs="12" sm="12" md="12">
                    <AdSelectFilters
                      districtId={districtId}
                      handleDistrictChange={handleDistrictChange}
                      districtOptions={districtOptions}
                      handleSemtChange={handleSemtChange}
                      semtOptions={semtOptions}
                      handleRankChange={handleRankChange}
                    />
                    <Calendar
                      semt={semtId}
                      rank={selectedRank}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </Col>
                  <hr style={{ border: "1px solid #D9D9D9" }} />
                  <Col xs="12" sm="12" md="12">
                    <Row
                      style={{ backgroundColor: "#FFF9EF", padding: "10px" }}
                      className="mb-4 "
                    >
                      <Col>
                        <div
                          style={{
                            color: "#7E7E7E",
                            fontSize: 13,
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            wordWrap: "break-word",
                          }}
                        >
                          Mevcut Tokenlar{" "}
                        </div>
                        <div
                          onClick={() => history("/tokens")}
                          style={{
                            color: "#D9921A",
                            fontSize: 10,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            textDecoration: "underline",
                            wordWrap: "break-word",
                            marginTop: "10px",
                            marginBottom: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Cüzdanınızı şarj etmek için burayı tıklayın
                        </div>
                      </Col>
                      <Col className="d-flex justify-content-end  align-items-center ">
                        <div
                          style={{
                            color: "#F1B44C",
                            fontSize: 16.5,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            wordWrap: "break-word",
                          }}
                        >
                          Bakiyeniz : {tokenCount} Token
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <h6 style={{ fontWeight: 700, fontSize: "16px" }}>
                          Toplam Ücret
                        </h6>
                      </Col>
                      <Col className="d-flex justify-content-end ">
                        <h4 style={{ color: "#556EE6" }}>{tokenCost} Token</h4>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center ">
                      {balanceError && (
                        <>
                          <Col className="d-flex justify-content-start">
                            <h4 style={{ fontWeight: 700, fontSize: "16px", color: "red" }}>Yetersiz Token </h4>
                          </Col>
                          <Col className="d-flex justify-content-end ">
                            <Button
                              onClick={() => history("/tokens")}
                              className="buyButton"
                            >
                              Token almak için tıklayınız !
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Kapat
            </Button>

            <Button
              onClick={() => {

                addPromotionMutation.mutate()
              }}
              color="primary"
            >
              Tokenla Öde
            </Button>

          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}

export default withRouter(NewAddPopup)

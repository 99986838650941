import React, { useState } from "react"
import { Button, Col, Row, CardTitle, Card, CardBody } from "reactstrap"
import { Alert } from 'antd';
import Marquee from 'react-fast-marquee';
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';
import { tr } from 'date-fns/locale'; // Import Turkish locale
import { Popconfirm } from 'antd';
import ApproveOrderModal from "../Popups/ApproveOrderModal.js";

const ProcessingInfoTable = ({
  order_id,
  name,
  customer_address,
  order_date,
  paid_amount,
  order_items,
  hasOrderedItem,
  refetch,
  shipping_tracking_no,
  shipping_tracking_url,
  orderItemId
}) => {
  
  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const cancelOrder = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/seller-cancel-order?order_id=${order_id}`
    return axios.get(finalUrl, { withCredentials: true })
  }
  
  const cancelOrderMutation = useMutation({
    mutationFn: cancelOrder,
    onSuccess: (data) => {
      // console.log(data)
      toast.success("Sipariş reddedildi")
      refetch()
    },
    onError: (error) => {
      console.log(error);
      if (error.response.data.detail[0].msg === "invalid order_id") {
        toast.error("Sipariş kodu bulunamadı")
      }else if(error.response.data.detail[0].msg === "can't return for now, try again later"){
        toast.info("Sipariş şu anda iptal edilemiyor lütfen daha sonra tekrar deneyin")
      } else {
        toast.error("Bir şeyler yanlış gitti");
      }
    }
  })
  const orderStatusTable = {
    "ordered": "Sipariş verildi",
    "preparing": "Hazırlanıyor",
    "shipped": "Kargoya verildi",
    "customer-cancelled": "Müşteri tarafından iptal edildi",
    "seller-cancelled": "Satıcı tarafından iptal edildi",
    "customer-delivered": "Teslim edildi",
    "success": "Tamamlandı",
    "returning": "İade ediliyor",
    "return-shipped": "İade kargoya verildi",
    "returned": "İade satıcıya ulaştı",
    "return-accepted": "İade onaylandı",
    "return-cancelled": "İade iptal edildi",
    "return-cancelled-shipped": "İade müşteri için kargoya verildi",
    "return-cancelled-delivered": "İade müşteriye ulaştı"
  }
  return (
    <>
      <Card>
        <Row className="d-flex justify-content-between align-items-center">
          <Col className="d-flex flex-column align-items-start">
            <span
              style={{ color: "#000000", fontWeight: 600, fontSize: "1rem" }}
            >
              Sipariş kodu: {order_id?order_id:"Sipariş kodu bulunamadı"}

            </span>
          </Col>

          {hasOrderedItem && (
            <Col>
              <Row className="d-flex justify-content-end align-items-center">

                <Col className="d-flex flex-column align-items-end">
                  <Popconfirm
                    title="Siparişi iptal et"
                    description="Bu siparişi iptal edeceğinizden emin misiniz?"
                    onConfirm={() => cancelOrderMutation.mutate()}
                    onCancel={() => console.log("cancel")}
                    okText="Evet"
                    cancelText="Hayir"
                  >
                    <Button color="danger">Siparişi iptal et</Button>
                  </Popconfirm>
                </Col>
                <Col className="d-flex flex-column align-items-end">
                  <Button onClick={() => toggleModal()} color="primary">Siparişi onayla</Button>
                  <ApproveOrderModal orderItemId={orderItemId} order_id={order_id} refetch={refetch} modelIsOpen={modalOpen} setModelIsOpen={setModalOpen} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        
        <hr style={{ height: 1, backgroundColor: "#EFEFEF" }} />
        <CardTitle className="p-4 pb-0 d-flex flex-row ">
          Sipariş Bilgileri
        </CardTitle>
        <CardBody>
          <Col>
            <table
              className="text-dark opacity-75 fs-6 pb-4"
              style={{ fontWeight: "500" }}
            >
              <tbody>

                <tr>
                  <td className="p-2 pe-4 ps-0">Ad</td>
                  <td>{name?name:"Ad bulunamadı"}</td>
                </tr>

                <tr>
                  <td className="p-2 pe-4 ps-0">Adres</td>
                  <td>{customer_address?customer_address:"Adres bulunamadı"}</td>

                </tr>

                <tr>
                  <td className="p-2 pe-4 ps-0">Satın Alma Tarihi</td>
                  <td>{order_date?order_date:"Satın alma tarihi bulunamadı"}</td>
                </tr>
                <tr>
                  <td className="p-2 pe-4 ps-0">Sipariş Tutarı</td>
                  <td>{paid_amount?`${paid_amount}₺`:"Sipariş tutarı bulunamadı"}</td>
                </tr>
                <tr>
                  <td className="p-2 pe-4 ps-0">Kargo takip numarası</td>
                  <td>{shipping_tracking_no?shipping_tracking_no:"Kargo takip numarası bulunamadı"}</td>

                </tr>
                <tr>
                  <td className="p-2 pe-4 ps-0">Kargo takip linki</td>
                  <td>
                    {shipping_tracking_url?(<a target="blank" href={shipping_tracking_url}>Takip linki</a>):"Kargo takip linki bulunamadı"}

                  </td>
                </tr>
                
              </tbody>
            </table>
            <div className="row">
              {order_items?.map((info, index) => (
                <div key={index} className="col-12 col-xxl-12 p-1 m-0">
                  <Card className="w-100 d-flex position-relative flex-row border rounded p-0 overflow-hidden">

                    <img
                      src={info.cover?info.cover:productPlaceholder}
                      className="border"
                      style={{ width: "20%", objectFit: "cover", borderRadius: "8px" }}
                    />
                    <div className="w-75 column p-3 py-auto">

                      <CardTitle className="fs-6" style={{ fontWeight: "500" }}>
                        {info.product_name?info.product_name:"Ürün adı bulunamadı"}
                      </CardTitle>
                      {info.status === "ordered" ? (
                        <Alert
                          banner
                          message={
                            <Marquee pauseOnHover gradient={false}>
                              {`Onay için son ${formatDistanceToNow(new Date(info.ordered_expires_at), { addSuffix: false, locale: tr })}`}
                            </Marquee>
                          }
                        />
                      ) : info.status === "preparing" ?
                        (null) :
                        (null)
                      }
                      <p className="p-0 m-0">
                        <small className="text-secondary opacity-75 me-1">
                          Ürün Kodu:
                        </small>
                        {info.product_id?info.product_id:"Ürün kodu bulunamadı"}
                      </p>
                      <p className="p-0 m-0">
                        <small className="text-secondary opacity-75 me-1">
                          Beden:
                        </small>
                        {info.size?info.size:"Beden bulunamadı"}
                      </p>
                      <p className="p-0 m-0">
                        <small className="text-secondary opacity-75 me-1">
                          Adet:
                        </small>
                        {info.quantity?info.quantity:"Adet bulunamadı"}
                      </p>
                      <p className="p-0 m-0">
                        <small className="text-secondary opacity-75 me-1">
                          Stok adedi:
                        </small>
                        {info.stock_quantity?info.stock_quantity:"Stok adedi bulunamadı"}
                      </p>
                      <p className="p-0 m-0">
                        <small className="text-secondary opacity-75 me-1">
                          Durum:
                        </small>
                        {orderStatusTable[info.status]?orderStatusTable[info.status]:"Durum bulunamadı"}
                      </p>
                      {info?.shipping_code &&
                        (
                          <p className="p-0 m-0">
                            <small className="text-secondary opacity-75 me-1">
                              Kargo kodu:
                            </small>
                            {info?.shipping_code}
                          </p>
                        )
                      }

                    </div>
                    <div
                      className="position-absolute rounded-circle"
                      style={{
                        backgroundColor: info?.color_hex,
                        border: "2px solid #C1BDBD",
                        width: "20px",
                        height: "20px",
                        bottom: "0",
                        left: "20%",
                        transform: "translate(-50%)",
                      }}
                    ></div>
                  </Card>
                </div>
              ))}
            </div>
          </Col>
        </CardBody>
      </Card>
    </>
  )
}

export default ProcessingInfoTable

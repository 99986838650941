import React from "react"
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import { useNavigate } from "react-router-dom"

const PendingContractsModal = ({ isOpen, toggle }) => {
  const history = useNavigate()
  const customModalStyles = {
    maxWidth: "550px", 
    width: "80%",
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={customModalStyles}>
      <ModalHeader toggle={toggle}>Önemli Bilgilendirme</ModalHeader>
      <ModalBody style={{ padding: "20px" }}>
        <div className="d-flex">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={150}
              height={150}
              viewBox="0 -3.28 242.707 242.707"
            >
              <g id="SVGRepo_iconCarrier">
                <defs>
                  <style>{".a{fill:#fff}.c{fill:#211715}"}</style>
                </defs>
                <path
                  d="M195.608 125.335c4.449 8.493 7.922 15.352 14.451 27.794-21.332 10.235-108.038 52.729-126.65 62.217-9.746-18.746-19.5-38.554-28.632-58.1C38.749 122.924 22.676 88.7 7.42 54.105 47.713 35.363 117.45 8.082 131.776 2.423c7.861 15.5 26.471 51.971 38.819 76.219"
                  className="a"
                />
                <path
                  d="M166.087 129.006a438.243 438.243 0 0 1-9.978 5.4c2.961-10.712 6.074-21.453 9.084-29.189.089.063.138.093.138.093 2.842 1.747 15.911 12.434 17.85 13.612-4.233 2.672-10.281 6.283-17.094 10.084Z"
                  className="a"
                />
                <path
                  d="M224.347 38.283a3.756 3.756 0 0 0-1.66.874c-11.019 12.574-49.549 56.209-57.611 66.012 2.1 1.1 14.754 11.431 17.723 13.5l.26.181c18.743-21.241 37.043-42.428 56.019-64 3.085-3.927.129-8.75-4.081-12.409-3.997-3.481-8.049-4.821-10.65-4.158Z"
                  style={{
                    fill: "#7d827d",
                  }}
                />
                <path
                  d="m156.823 134.027-.714.375c1.45-5.246 2.937-10.5 4.43-15.4 2.813 1.837 8.066 5.8 10.056 7.456a439.35 439.35 0 0 1-13.772 7.569Z"
                  className="c"
                />
                <path
                  d="M193.536 126.546c4.844 9.251 9.6 18.547 14.451 27.794l.861-3.284q-9.48 4.551-18.926 9.174-14.02 6.828-28.026 13.69-15.475 7.58-30.937 15.18-14.079 6.924-28.142 13.88c-6.393 3.165-12.788 6.329-19.154 9.551q-.733.37-1.465.743l3.284.861C74.913 193.8 64.871 173.2 55.17 152.434c-9.9-21.182-19.81-42.358-29.53-63.623Q17.456 70.9 9.492 52.894l-.861 3.284c18.16-8.443 36.634-16.215 55.139-23.863q26-10.744 52.162-21.07 8.236-3.264 16.482-6.507l-2.71-1.1q14.931 29.442 29.962 58.834 4.43 8.692 8.856 17.384c1.4 2.754 5.545.327 4.145-2.422Q156.541 45.762 140.4 14.1q-3.279-6.442-6.551-12.888a2.4 2.4 0 0 0-2.71-1.1q-18.595 7.341-37.162 14.755c-19.343 7.773-38.639 15.672-57.8 23.877-10.044 4.3-20.056 8.683-29.965 13.289-1.266.588-1.342 2.192-.861 3.284 9.4 21.3 19.1 42.471 28.909 63.59s19.529 42.26 29.73 63.178q8.457 17.344 17.351 34.472a2.445 2.445 0 0 0 3.284.861c5.859-2.986 11.754-5.9 17.645-8.821q13.671-6.779 27.365-13.508 15.441-7.6 30.9-15.169 14.356-7.033 28.727-14.04 10.17-4.958 20.357-9.882l1.658-.8a2.438 2.438 0 0 0 .861-3.284c-4.852-9.246-9.607-18.543-14.452-27.793-1.433-2.738-5.577-.314-4.144 2.422Z"
                  className="c"
                />
                <path
                  d="M203.783 169.492q-9.48 4.55-18.925 9.173-14.022 6.828-28.027 13.69-15.475 7.577-30.937 15.179-14.079 6.924-28.141 13.88c-6.394 3.166-12.789 6.329-19.155 9.551q-.732.37-1.465.744l3.284.861c-10.569-20.338-20.611-40.936-30.312-61.7-9.9-21.183-19.81-42.359-29.529-63.624Q12.391 89.338 4.427 71.329a2.416 2.416 0 0 0-3.283-.861 2.46 2.46 0 0 0-.861 3.284c9.4 21.305 19.1 42.472 28.908 63.59S48.72 179.6 58.921 200.52q8.459 17.344 17.351 34.472a2.445 2.445 0 0 0 3.284.861c5.859-2.985 11.754-5.9 17.645-8.821q13.671-6.777 27.365-13.508 15.442-7.6 30.9-15.169 14.358-7.032 28.727-14.04 10.17-4.958 20.358-9.882l1.657-.8a2.422 2.422 0 0 0 .861-3.283 2.452 2.452 0 0 0-3.284-.861ZM167.028 106.557c5.287-6.384 10.783-12.6 16.231-18.844q11.594-13.288 23.25-26.524c5.922-6.737 11.727-13.6 17.779-20.226.053-.057.231-.239-.044-.015a1.642 1.642 0 0 1 .286-.181c.43-.214.031-.073.4-.156a3.69 3.69 0 0 1 1.472-.064 11.4 11.4 0 0 1 4.286 1.687c2.872 1.739 6.338 4.676 7.15 8.1.368 1.553-.28 2.6-1.281 3.743q-2.377 2.7-4.75 5.41-4.641 5.291-9.27 10.592-18.454 21.121-36.92 42.232-2.268 2.577-4.54 5.152c-2.037 2.308 1.345 5.716 3.394 3.394Q203.758 99.01 222.859 77q4.864-5.571 9.735-11.135 2.448-2.794 4.9-5.588c1.449-1.651 3.352-3.318 4.342-5.3 1.913-3.824.424-7.825-2.044-10.987a23.156 23.156 0 0 0-10.21-7.573c-2.87-1.017-6.554-1.136-8.785 1.262-1.182 1.27-2.293 2.614-3.438 3.917q-4.82 5.484-9.643 10.965-12.036 13.686-24.045 27.4c-6.285 7.189-12.6 14.361-18.751 21.664q-.646.766-1.286 1.538a2.468 2.468 0 0 0 0 3.394 2.419 2.419 0 0 0 3.394 0Z"
                  className="c"
                />
                <path
                  d="M164.12 107.379c.854.53 1.659 1.145 2.462 1.748q.9.677 1.8 1.365l.284.219.3.23 1.116.868c2.7 2.108 5.383 4.241 8.091 6.341 1.129.876 2.251 1.768 3.423 2.587a2.419 2.419 0 0 0 3.283-.861 2.448 2.448 0 0 0-.861-3.283 38.648 38.648 0 0 1-1.092-.8q-.327-.246-.653-.495l-.357-.273-.262-.2c.267.206-.2-.156-.291-.226l-.418-.326c-.318-.247-.636-.5-.954-.744l-2.049-1.608c-2.791-2.2-5.576-4.4-8.407-6.544-.974-.737-1.946-1.5-2.984-2.142a2.4 2.4 0 1 0-2.423 4.145Z"
                  className="c"
                />
                <path
                  d="M163.017 104.222c-3.758 9.6-6.476 19.615-9.223 29.542a2.428 2.428 0 0 0 3.526 2.71c9.066-4.749 18-9.784 26.669-15.237 2.608-1.641.2-5.8-2.423-4.144-8.664 5.452-17.6 10.487-26.669 15.237l3.526 2.71c2.747-9.927 5.465-19.939 9.223-29.542a2.419 2.419 0 0 0-1.677-2.952 2.455 2.455 0 0 0-2.952 1.676ZM52.7 92.579c10.533-5.328 21.151-10.487 31.8-15.588q16.766-8.035 33.668-15.783c4.885-2.226 9.782-4.43 14.719-6.537a2.414 2.414 0 0 0 .861-3.284 2.465 2.465 0 0 0-3.284-.861c-9.686 4.134-19.244 8.577-28.783 13.037q-17.322 8.1-34.5 16.491-8.474 4.138-16.9 8.38c-2.757 1.395-.33 5.537 2.423 4.145ZM70.353 128.733q25.154-13.371 51.212-24.956 7.062-3.16 14.184-6.182a2.415 2.415 0 0 0 .861-3.283 2.465 2.465 0 0 0-3.284-.861q-25.981 11.012-51.181 23.777-7.144 3.606-14.214 7.36c-2.729 1.45-.307 5.595 2.422 4.145ZM87.435 164.016q22.091-11.74 44.957-21.956 6.127-2.739 12.313-5.344a2.481 2.481 0 0 0 1.677-2.952 2.416 2.416 0 0 0-2.953-1.676c-15.528 6.507-30.791 13.653-45.815 21.251q-6.333 3.2-12.6 6.532c-2.729 1.45-.306 5.595 2.423 4.145Z"
                  className="c"
                />
              </g>
            </svg>
          </div>

          <div style={{ padding: "9px", marginLeft: "20px" }}>
            <span style={{ fontSize: "14px" }}>
              Bazı sözleşmelerde değişikliğe gidilmiştir.
              <br />
              Lütfen sözleşmeleri görüntüleyiniz.
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
              }}
            >
              <Button
                color="secondary"
                onClick={() => {
                  history("/contracts") 
                  toggle()
                }}
              >
                Sözleşmeleri Görüntüle
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PendingContractsModal
